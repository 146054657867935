import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectProjectDetails } from "../../../store/projects/projectsSlice";

export default function BudgetStatus() {
  const projectDetails = useSelector(selectProjectDetails);

  return (
    <div className="rtl mt-16 bg-[#e2e1e1] flex flex-col gap-5 items-start py-4 px-4 rounded-bl-3xl  relative">
      <Row gutter={76}>
        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 h-[36px] text-center text-[15px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تعداد کل صورت وضعیت ها
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.totalProceedingsQnt}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 h-[36px] text-center leading-none text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              هزینه های ثبت شده پروژه تا قبل از سال 1402
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.projectCostsRecordedBefore_1402}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={76}>
        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 h-[36px] text-center leading-none text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تعداد صورت وضعیت های نهایی شده
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.finalizedSituationsQnt}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 h-[36px] text-center leading-none text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              واریزی های پروژه توسط شرکت اسکان در سال 1402
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.projectDepositsByEskan_1402}
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={76}>
        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 h-[36px] leading-none text-center  text-[15px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              جمع مبالغ ناخالص کارکرد تایید شده
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.approvedGrossSum}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 h-[36px] text-center leading-none text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              واریزی های پروژه توسط بنیاد علوی در سال 1402
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.projectDepositsByAlavi_1402}
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={76}>
        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 leading-none text-[15px] text-center flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              هزینه تجمعی صورت وضعیت ها
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.cumulativeCosts}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 text-[15px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مجموع هزینه ثبت شده
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.totalRecordedCosts}
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={76}>
        <Col span={12}>
          <div className="flex gap-3">
            <div className=" text-white w-48 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg">
              {/* پیشرفت فیزیکی (PV) */}
            </div>
            <div className=" text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"></div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-48 text-[15px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              باقی مانده قابل هزینه
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-[330px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.remainderPayable}
            </div>
          </div>
        </Col>
      </Row>
      <span className="bg-[#D4D1DD] font-bold py-[2px] px-9 rounded-tr-3xl text-#14014B text-lg absolute top-[-31px] right-0">
        وضعیت بودجه و صورت وضعیت های پروژه
      </span>
    </div>
  );
}

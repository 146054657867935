import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectProjectDetails } from "../../../store/projects/projectsSlice";

export default function ProjectSchedule() {
  const projectDetails = useSelector(selectProjectDetails);

  return (
    <div className="rtl mt-16 bg-[#e2e1e1] flex flex-col gap-5 items-start py-4 px-4 rounded-bl-3xl  relative">
      <Row gutter={76}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 h-[36px] text-center leading-none text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ اسناد فنی و نقشه ها
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.technicalDocumentDate
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مدت روز (E)
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.durationInDate_E}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-[15px] h-[36px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مدت تمدید (روز)
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.extensionPeriod}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={76}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 h-[36px] text-center leading-none text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ شروع واقعی با اولین پرداخت
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.actualStartingDateOrFirstPayment
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32  text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مدت روز (P)
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.durationInDate_P}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 h-[36px] text-center text-[14px] leading-none flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ اتمام (پیش بینی شده)
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.predictedEndDate
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={76}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ واقعی
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.actualEndDate
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 h-[36px] text-[16px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مدت روز (C)
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.durationInDate_C}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-[14px] h-[36px] text-center flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ اتمام (واقعی)
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.actualEndDate
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>
      </Row>
      <span className="bg-[#D4D1DD] font-bold py-[2px] px-9 rounded-tr-3xl text-#14014B text-lg absolute top-[-31px] right-0">
        برنامه زمانبندی پروژه
      </span>
    </div>
  );
}

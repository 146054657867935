// import { PropsWithChildren } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

//import pages
// import TicketsPage from "./tickets/TicketsPage";
import ErrorPage from "./errors/ErrorPage";
import LoginPage from "./auth/LoginPage";
import DashboardPage from "./dashboard/DashboardPage";
import ProjectsPage from "./projects/ProjectsPage";
import ReportsPage from "./reports/ReportsPage";
// import UsersPage from "./users/UsersPage";
// import SSOLogin from "./auth/SSOLoginPage";
// import UsersCreatePage from "./users/UsersCreatePage";
// import UsersEditPage from "./users/UsersEditPage";
// import InstancesPage from "./instances/InstancesPage";
// import IndicationsPage from "./indications/IndicationsPage";
// import IncidencePage from "./indications/IncidencePage";
// import SettingsPage from "./settings/SettingsPage";
// import InstanceUsers from "./instance-users/InstanceUsers";
// import PayersPage from "./payers/PayersPage";
// import CustomersPage from "./payers/CustomersPage";
// import ProductsPage from "./products/ProductsPage";
// import TreatmentsPage from "./treatments/TreatmentsPage";
// import ProductPage from "./products/ProductPage";
// import ProjectsPage from "./projects/ProjectsPage";
// import ProjectPage from "./projects/ProjectPage";
// import ScenarioPage from "./projects/ScenarioPage";

//import component
import PanelLayout from "../components/PanelLayout";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../store/auth/authSlice";
import ProgressPage from "./progress/ProgressPage";
import LocationPage from "./location/LocationPage";
import NotificationsPage from "./notifications/NotificationsPage";
import FollowUpPage from "./followUp/FollowUpPage";
import UploadPage from "./upload/UploadPage";
import ProjectDetailsPage from "./projects/ProjectDetailsPage";
// import InstancesLayout from "../components/Instances/InstancesLayout";

//import hooks
// import { useAppSelector } from "../hooks";
// import { selectIsAuthenticate } from "../store/auth/authSlice";

//custom routes list
// import { settingMegaMenus } from "../pages/settings/SettingsPage";
// import TreatmentPage, { treatmentMegaMenus } from "./treatments/TreatmentPage";
// import { ticketsMegaMenus } from "../pages/tickets/TicketsPage";
// import DataStewardshipPage from "../components/Tickets/dataStewardship/DataStewardshipPage";

function PrivateRoute({ children }) {
  let loggedIn = useSelector(selectIsAuthenticated);

  return loggedIn ? <>{children}</> : <Navigate to="/login" />;
}

const routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <PanelLayout />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "projects",
        children: [
          {
            index: true,
            element: <ProjectsPage />,
          },
          {
            path: ":projectId",
            element: <ProjectDetailsPage />,
          },
        ],
      },
      {
        path: "/reports",
        element: <ReportsPage />,
      },
      {
        path: "/progress",
        element: <ProgressPage />,
      },
      {
        path: "/Location",
        element: <LocationPage />,
      },
      {
        path: "/upload",
        element: <UploadPage />,
      },
      {
        path: "/notifications",
        element: <NotificationsPage />,
      },
      {
        path: "/follow-up",
        element: <FollowUpPage />,
      },
    ],
    // {
    //   path: "/tickets",
    //   element: <TicketsPage />,
    //   children: [
    //     ...ticketsMegaMenus.map((s) => ({ path: s.key, element: s.element })),
    //     {
    //       path: "dataStewardship/:ticketId",
    //       element: <DataStewardshipPage />,
    //     },
    //   ],
    // },
    // {
    //   path: "/users",
    //   element: <UsersPage />,
    // },
    // {
    //   path: "/users/create",
    //   element: <UsersCreatePage />,
    // },
    // {
    //   path: "/users/:userId/edit",
    //   element: <UsersEditPage />,
    // },
    // {
    //   path: "/instances",
    //   element: <InstancesPage />,
    // },
    // {
    //   path: "/instances/:instanceName",
    //   element: <InstancesLayout />,
    //   children: [
    //     {
    //       index: true,
    //       element: <ProjectsPage />,
    //     },
    //     {
    //       path: "indications",
    //       element: <IndicationsPage />,
    //     },
    //     {
    //       path: "indications/:indicationId/incidence",
    //       element: <IncidencePage />,
    //     },
    //     {
    //       path: "settings",
    //       element: <SettingsPage />,
    //       children: [
    //         ...settingMegaMenus.map((s) => ({
    //           path: s.key,
    //           element: s.element,
    //         })),
    //       ],
    //     },
    // {
    //   path: "users",
    //   element: <InstanceUsers />,
    // },
    // {
    //   path: "payers",
    //   children: [
    //     {
    //       index: true,
    //       element: <PayersPage />,
    //     },
    //     {
    //       path: ":countryId/customers",
    //       element: <CustomersPage />,
    //     },
    //   ],
    // },
    // {
    //   path: "products",
    //   children: [
    //     {
    //       index: true,
    //       element: <ProductsPage />,
    //     },
    //     {
    //       path: ":productId",
    //       element: <ProductPage />,
    //     },
    //   ],
    // },
    // {
    //   path: "treatments",
    //   children: [
    //     {
    //       index: true,
    //       element: <TreatmentsPage />,
    //     },
    //     {
    //       path: ":treatmentId",
    //       element: <TreatmentPage />,
    //       children: [
    //         ...treatmentMegaMenus.map((s) => ({
    //           path: s.key,
    //           element: s.element,
    //         })),
    //       ],
    //     },
    //   ],
    // },
    // {
    //   path: "projects",
    //   children: [
    //     {
    //       index: true,
    //       element: <ProjectsPage />,
    //     },
    //     {
    //       path: ":projectId",
    //       element: <ProjectPage />,
    //     },
    //     {
    //       path: ":projectId/scenario-view/:scenarioId",
    //       element: <ScenarioPage />,
    //     },
    //   ],
    // }
    // ],
  },
  // ],
  // },
  {
    path: "/login",
    element: <LoginPage />,
  },

  // {
  //   path: "/login-with-sso",
  //   element: <SSOLogin />,
  // },
];

export const router = createBrowserRouter(routes);

import { Form, Spin, Button } from "antd";
import jalaliMoment from "jalali-moment";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  closeLocationModal,
  selectLoading,
  selectLocationModal,
  selectSelectedLocation,
  selectSelectedProject,
  submitLocationAsync,
} from "../../store/location/locationSlice";
import LocationMap from "../Location/map/LocationMap";
import "../Location/styles/LocationMap.css";
import Modal from "react-modal";
import { CloseOutlined } from "@ant-design/icons";

export default function LocationModal() {
  const dispatch = useDispatch();
  const [LocationForm] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const loading = useSelector(selectLoading);
  const isModalOpen = useSelector(selectLocationModal);
  const selectedProject = useSelector(selectSelectedProject);
  const selectedLocation = useSelector(selectSelectedLocation);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // handle success error on submiting progress form
  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    onClose: () => {
      dispatch(closeLocationModal());
      setSubmitLoading(false);
      setShowError(false);
    },
  };
  //
  const handleSuccess = () => {
    toast.success("ثبت موقعیت مکانی پروژه با موفقیت انجام شد.", toastOptions);
    LocationForm.resetFields();
  };
  const onFinish = async () => {
    if (selectedLocation.length > 0 && selectedProject?.eskanIranCode) {
      setSubmitLoading(true);
      dispatch(
        submitLocationAsync({
          eskanIranCode: selectedProject?.eskanIranCode,
          newLocation: {
            coordinates: selectedLocation,
          },
          date: jalaliMoment(new Date()).format("jYYYYjMjD HH:mm:ss"),
        })
      ).then((action) => {
        if (
          action.payload !== undefined &&
          action.payload.response.status === 200
        ) {
          handleSuccess();
        }
      });
    }
    if (!selectedLocation.length > 0) {
      setShowError(true);
    }
    return null;
  };

  const closeModal = () => {
    dispatch(closeLocationModal());
    setShowError(false);
  };

  useEffect(() => {
    // Set the app element for screen readers
    Modal.setAppElement("#root");
  }, []);
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={300}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: 1000,
        },
        content: {
          width: "600px",
          height: "575px",
          direction: "rtl",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Spin spinning={loading}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Form
          labelCol={{
            span: 8.5,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
          style={{
            maxWidth: 700,
            // direction: "rtl",
          }}
          form={LocationForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="flex justify-between items-center">
            <h3>
              <span className="font-bold">پروژه:</span>
              <span className="mr-1 font-bold">
                {selectedProject?.eskanIranCode}
              </span>
            </h3>
            <div className="close-btn">
              <Button
                className="text-center"
                icon={<CloseOutlined />}
                onClick={closeModal}
              ></Button>
            </div>
          </div>
          <h3 className="text-red-500 text-sm">
            برای ثبت درست موقعیت تا حد امکان به محل نزدیک شده و نشانگر را در
            موقعیت قرار دهید.
          </h3>
          <LocationMap />
          {showError && selectedLocation.length === 0 && (
            <h3 className="text-red-500">
              موقعیت مکانی پروژه را روی نقشه مشخص کنید.
            </h3>
          )}
          <Form.Item>
            <Button
              className="bg-indigo-900 w-[100px]"
              type="primary"
              htmlType="submit"
              disabled={submitLoading}
            >
              ثبت
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

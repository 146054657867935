import { Card, Col, Row, Space } from "antd";
import styles from "./styles/DashHeader.module.scss";

import ProgressChart from "./charts/ProgressChart";
import ProgressStatistics from "./ProgressStatistics";

export default function DashboardBottom() {
  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      {/* <Spin spinning={loading}> */}
      <Row
        justify="space-between"
        className={styles.DashboardTop}
        gutter={24}
        style={{ margin: "10px 0px 40px 0px" }}
      >
        <Col span={16}>
          <Card
            style={{
              height: 180,
              width: "100%",
              boxShadow:
                "5px 5px 5px 0px rgba(20,1,75,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)," +
                "0px 0px 5px 0px rgba(135,116,225,0.3)",
              border: "none",
              position: "relative",
              font: "16px",
              fontWeight: "bold",
              borderRadius: "0px",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 0,
            }}
          >
            <span
              style={{
                position: "absolute",
                right: 0,
                top: -20,
                backgroundColor: "#D4D1DD",
                borderRadius: 5,
                padding: "0px 10px",
                color: "#14014B",
              }}
            >
              نمودار ماهانه معادل ریالی پیشرفت برنامه ای و فیزیکی منطقه
            </span>

            <ProgressChart />
          </Card>
        </Col>
        <Col span={8} style={{ paddingRight: 0 }}>
          <Card
            style={{
              height: 180,
              width: "100%",
              boxShadow:
                "5px 5px 5px 0px rgba(20,1,75,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)," +
                "0px 0px 5px 0px rgba(135,116,225,0.3)",
              border: "none",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              font: "16px",
              fontWeight: "bold",
              borderRadius: "0px",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 2,
            }}
          >
            <span
              style={{
                position: "absolute",
                right: 0,
                top: -20,
                backgroundColor: "#D4D1DD",
                borderRadius: 5,
                padding: "0px 10px",
                color: "#14014B",
              }}
            >
              وضعیت پیشرفت پروژه ها
            </span>
            <ProgressStatistics />
          </Card>
        </Col>
      </Row>
      {/* </Spin> */}
    </Space>
  );
}

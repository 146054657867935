import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import GeographicalData from "./project/GeographicalData";
import CancelationStatus from "./project/CancelationStatus";
import SubjectClassification from "./project/SubjectClassification";
import ContractInformation from "./project/ContractInformation";
import ProjectStatus from "./project/ProjectStatus";
import StopProject from "./project/StopProject";
import ProjectSchedule from "./project/ProjectSchedule";
import ProgressStatus from "./project/ProgressStatus";
import BudgetStatus from "./project/BudgetStatus";
import {
  projectDetailsAsync,
  selectLoading,
} from "../../store/projects/projectsSlice";
import ProjectLinks from "./project/ProjectLinks";

export default function ProjectDetails() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const loading = useSelector(selectLoading);

  useEffect(() => {
    if (projectId) dispatch(projectDetailsAsync(projectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <Spin spinning={loading}>
      <div>
        <Breadcrumb style={{ direction: "rtl", fontSize: "18px" }}>
          <Breadcrumb.Item>
            <Link to="/projects" style={{ cursor: "pointer" }}>
              پروژه ها
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{`پروژه ${projectId}`}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <GeographicalData />
      <CancelationStatus />
      <SubjectClassification />
      <ContractInformation />
      <ProjectStatus />
      <StopProject />
      <ProjectSchedule />
      <ProgressStatus />
      <BudgetStatus />
      <ProjectLinks />
    </Spin>
  );
}

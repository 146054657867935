import LocationsTable from "../../components/Location/LocationsTable";
import LocationModal from "../../components/Modals/LocationModal";

export default function LocationPage() {
  return (
    <div className="rtl p-4">
      <LocationsTable />
      <LocationModal />
    </div>
  );
}

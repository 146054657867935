import { useEffect, useState } from "react";
// import * as ReactDOMServer from "react-dom/server";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  // GeoJSON,
  // useMapEvents,
} from "react-leaflet";
// import PieMarker from "../charts/PieChart";
// import Legend from "./Legend";
// import { selectMapRegions } from "../../../store/map/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectRegion } from "../../../store/region/regionSlice";
import {
  fetchRegionLocationsAsync,
  selectRegionLocations,
} from "../../../store/location/locationSlice";

const bounds = [
  [25, 44],
  [40.2, 64],
];
// eslint-disable-next-line no-unused-vars
const pinIcon = L.divIcon({
  html: `<svg fill="rgb(240,0,0)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 width="30px" height="30px" viewBox="0 0 425.963 425.963"
	 xml:space="preserve">
<g>
	<path d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
		c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
		c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
		C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
		c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"/>
</g>
</svg>`,
  className: "pin-icon",
});

const LeafletMap = () => {
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [geoJSON, setGeoJSON] = useState(null);
  // const mapRegions = useSelector(selectMapRegions);
  // eslint-disable-next-line no-unused-vars
  // const [selectedPosition, setSelectedPosition] = useState([
  //   32.528119, 52.764889,
  // ]);
  const selectedRegion = useSelector(selectRegion);
  const locationsList = useSelector(selectRegionLocations);
  // const [pinPosition, setPinPosition] = useState([32.528119, 52.764889]);
  // eslint-disable-next-line no-unused-vars
  // const [isDragging, setIsDragging] = useState(false);
  // custom GeoJSON for quicker loading
  const CustomGeoJSONLayer = ({ data, selectedRegion }) => {
    const map = useMap();

    useEffect(() => {
      if (data && selectedRegion) {
        const geoJSONLayer = L.geoJSON(data, {
          style: { fillColor: "#fff", color: "#800080", dashArray: "5, 5" },
          filter: (feature) => {
            return feature.properties.name === selectedRegion.targetRegion;
          },
          onEachFeature: (feature, layer) => {
            layer.bindPopup(feature.properties.name);
          },
        });

        geoJSONLayer.addTo(map);

        return () => {
          map.removeLayer(geoJSONLayer);
        };
      }
    }, [data, selectedRegion, map]);

    return null; // React requires a return statement, but we don't render anything directly
  };
  //

  useEffect(() => {
    dispatch(fetchRegionLocationsAsync(selectedRegion?.regionCode));
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/eskanRegion.geojson`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const geojsonData = await response.json();
        setGeoJSON(geojsonData);
        // eslint-disable-next-line no-unused-vars
        const geoJSONBounds = L.geoJSON(geojsonData).getBounds();
        // setMapBounds(geoJSONBounds);
      } catch (error) {
        console.error("Error loading GeoJSON:", error);
      }
    };
    fetchGeoData();
  }, []);

  useEffect(() => {
    // const setMapBounds = (bounds) => {
    if (map && geoJSON && selectedRegion) {
      const selectedFeature = geoJSON.features.find(
        (feature) => feature.properties.name === selectedRegion.targetRegion
      );
      if (selectedFeature) {
        const bounds = L.geoJSON(selectedFeature).getBounds();
        map.fitBounds(bounds);
        // const bounds = L.geoJSON(geoJSON).getBounds();
        // map.fitBounds(bounds);
        setGeoJSON(geoJSON);
      }
    }
    // };
  }, [map, geoJSON, selectedRegion, locationsList]);

  // const handleMapClick = (e) => {
  //   const { lat, lng } = e.latlng;
  //   setSelectedPosition([lat, lng]);
  // };
  // const MapClickHandler = () => {
  //   useMapEvents({
  //     click: handleMapClick,
  //   });

  //   return null;
  // };

  // const handleMapDrag = () => {
  //   requestAnimationFrame(() => {
  //     const center = map.getCenter();
  //     setSelectedPosition([center.lat, center.lng]);
  //   });
  // };

  // const MapDragHandler = () => {
  //   useMapEvents({
  //     drag: handleMapDrag,
  //   });

  //   return null;
  // };

  return (
    <MapContainer
      center={[32.528119, 52.764889]}
      zoom={5}
      scrollWheelZoom={false}
      style={{ height: 430, width: "100%" }}
      whenReady={(map) => setMap(map.target)}
      // maxBounds={bounds}
      maxBoundsViscosity={1.0}
    >
      {/* <MapClickHandler /> */}
      {/* <MapDragHandler /> */}
      <TileLayer
        attribution="|Eskan Iran"
        // attribution='|Eskan Iran; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        bounds={bounds}
        minZoom={5}
        // maxZoom={7}
      />
      {/* {mapRegions.map((reg) => {
        const { latitude, longitude, region } = reg;

        // Create  data
        const data = [
          {
            title: reg.completeOpeVol,
            value: reg.completeOpeVol,
            color: "#00CC00",
          },
          {
            title: reg.incompleteOpeVol,
            value: reg.incompleteOpeVol,
            color: "#c93636",
          },
        ];

        const icon = L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(
            <PieMarker data={data} regionName={region} />
          ),
        });

        return (
          <Marker position={[latitude, longitude]} icon={icon}>
            <Popup>{region}</Popup>
          </Marker>
        );
      })} */}
      <MarkerClusterGroup chunkedLoading>
        {locationsList?.length > 0 &&
          locationsList.map((location) => {
            const { eskanIranCode, coordinates } = location;

            // Check if coordinates exist and is an array with two values
            if (
              coordinates &&
              Array.isArray(coordinates) &&
              coordinates.length > 0
            ) {
              // Map through the coordinates array and render a marker for each set of coordinates
              return coordinates.map(([latitude, longitude], index) => (
                <Marker
                  key={`${eskanIranCode}_${index}`} // Ensure a unique key for each marker
                  position={[latitude, longitude]}
                  icon={pinIcon}
                >
                  <Popup
                    style={{ direction: "rtl" }}
                  >{`${eskanIranCode} :پروژه`}</Popup>
                </Marker>
              ));
            }
            return null; // Ignore locations with invalid coordinates
          })}
      </MarkerClusterGroup>
      {/* <Legend map={map} /> */}
      {/* {geoJSON && selectedRegion && (
        <GeoJSON
          key={JSON.stringify(selectedRegion)}
          data={geoJSON}
          style={{ fillColor: "#fff", color: "#800080", dashArray: "5, 5" }}
          filter={(feature) => {
            return (
              feature.properties.name ===
              `استان ${selectedRegion && selectedRegion.province}, ایران`
            );
          }}
        />
      )} */}
      {geoJSON && selectedRegion && (
        <CustomGeoJSONLayer data={geoJSON} selectedRegion={selectedRegion} />
      )}
    </MapContainer>
  );
};

export default LeafletMap;

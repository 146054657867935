import UploadHeader from "../../components/Upload/UploadHeader";

const UploadPage = () => {
  return (
    <>
      <UploadHeader />
    </>
  );
};

export default UploadPage;

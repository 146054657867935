import { jwtAxios } from ".";

export const LOCATION_API_URL = `/locations`;
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function regionLocationsApi(regionCode) {
  return jwtAxios.get(BASE_URL + LOCATION_API_URL + `/${regionCode}`);
}

export function addLocationApi({ eskanIranCode, newLocation, date }) {
  return jwtAxios.post(BASE_URL + LOCATION_API_URL + `/add-location`, {
    eskanIranCode,
    newLocation,
    date,
  });
}

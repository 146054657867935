import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  projectsAsync,
  selectLoading,
  selectProjects,
} from "../../store/projects/projectsSlice";
import { Button, Input, Space, Table, Tooltip, Typography, theme } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./styles/ProjectsTable.css";
import { selectSelectedRegion } from "../../store/region/regionSlice";

export default function ProjectsTable() {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const loading = useSelector(selectLoading);
  const selectedRegion = useSelector(selectSelectedRegion);
  const [filteredInfo, setFilteredInfo] = useState([]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // fetch projects
  useEffect(() => {
    dispatch(projectsAsync(selectedRegion));
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    const uniqueStatuses = [
      ...new Set(projects && projects.map((p) => p.executiveStatus)),
    ];
    const filtered = uniqueStatuses.map((status) => {
      return {
        text: status,
        value: status,
      };
    });
    setFilteredInfo(filtered);
  }, [projects]);

  // sort ascending
  const codeRegex = /([a-zA-Z]+)(\d+)/;

  const dataSource =
    projects &&
    projects
      .map((project, index) => ({ ...project, key: index }))
      .sort((a, b) => {
        const codeA = a.eskanIranCode.match(codeRegex);
        const codeB = b.eskanIranCode.match(codeRegex);

        const lettersA = codeA[1];
        const lettersB = codeB[1];

        if (lettersA < lettersB) return -1;
        if (lettersA > lettersB) return 1;

        const numA = parseInt(codeA[2]);
        const numB = parseInt(codeB[2]);

        if (numA < numB) return -1;
        if (numA > numB) return 1;

        return 0;
      });

  const columns = [
    {
      title: "کد پروژه",
      dataIndex: "code",
      key: "code",
      width: "7%",
      render: (_, project) => (
        <Space size="small">
          <Typography.Text>{project.eskanIranCode}</Typography.Text>
        </Space>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.eskanIranCode
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            // placeholder={`...جستجو`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "شرح پروژه",
      dataIndex: "description",
      key: "description",
      width: "10%",
      render: (_, project) => (
        <Tooltip title={project.projectDescription}>
          <div style={{ maxHeight: 20, overflow: "hidden" }}>
            <Space size="small">
              <Typography.Text>{project.projectDescription}</Typography.Text>
            </Space>
          </div>
        </Tooltip>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.projectDescription
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{ padding: 8, direction: "rtl" }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            // placeholder={`...جستجو`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "کد پروژه علوی",
      dataIndex: "bonyad",
      key: "bonyad",
      render: (_, project) => (
        <Space size="small">
          <Typography.Text>{project.bonyadCode}</Typography.Text>
        </Space>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.bonyadCode
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            // placeholder={`...جستجو`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "شماره نامه ابلاغیه",
      dataIndex: "letterNumber",
      key: "letterNumber",
      render: (_, project) => (
        <Space size="small">
          <Typography.Text>{project.notificationLetterNumber}</Typography.Text>
        </Space>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.notificationLetterNumber
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            // placeholder={`...جستجو`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "تاریخ ابلاغ",
      dataIndex: "date",
      key: "date",
      render: (_, project) => (
        <Space size="small">
          <Typography.Text>
            {project.notificationDate
              .toString()
              .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
          </Typography.Text>
        </Space>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.notificationDate
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            // placeholder={`...جستجو`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "مبلغ مصوب (ریال)",
      dataIndex: "approvedAmount",
      key: "approvedAmount",
      render: (_, project) => (
        <Space size="small">
          <Typography.Text>{project.approvedAmount}</Typography.Text>
        </Space>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.approvedAmount
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            // placeholder={`...جستجو`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "اصلاح مصوبه (شماره و تاریخ نامه)",
      width: "10%",
      dataIndex: "amendment",
      key: "amendment",
      render: (_, project) => (
        <Space size="small">
          <Typography.Text>{project.amendmentResolution}</Typography.Text>
        </Space>
      ),
    },

    {
      title: "وضعیت",
      dataIndex: "status",
      key: "status",
      render: (_, project) => (
        <Space size="middle">
          <Typography.Text>{project.executiveStatus}</Typography.Text>
        </Space>
      ),
      filterSearch: true,
      filters: filteredInfo || null,
      onFilter: (value, record) => record.executiveStatus === value,
    },
    {
      title: "پیشرفت فیزیکی",
      dataIndex: "physicalProgress",
      key: "physicalProgress",
      render: (_, project) => (
        <Space size="middle">
          <Typography.Text>{project.physicalProgress}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "پیشرفت برنامه ای",
      dataIndex: "programmaticProgress",
      key: "programmaticProgress",
      render: (_, project) => (
        <Space size="middle">
          <Typography.Text>{project.programmaticProgress}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "پیشرفت بودجه ای",
      dataIndex: "budgetedProgress",
      key: "budgetedProgress",
      render: (_, project) => (
        <Space size="middle">
          <Typography.Text>{project.budgetedProgress}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "انحراف زمانی",
      dataIndex: "deviation",
      key: "deviation",
      render: (_, project) => (
        <Space size="middle">
          <Typography.Text>{project.deviationTime}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "اطلاعات بیشتر",
      key: "more",

      render: (_, project) => (
        <Space size="small">
          <Link to={`${project.eskanIranCode}`}>
            <Button
              type="default"
              // icon={<EyeOutlined />}
              loading={false}
              style={{
                backgroundColor: "#14014B",
                color: "#fff",
                boxShadow:
                  "inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5)",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#4F3C7B")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#14014B")
              }
            >
              مشاهده
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{ padding: 24, background: colorBgContainer, borderRadius: 10 }}
    >
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div className="table">
          <Table
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{
              showSizeChanger: false,
              position: ["bottomCenter"],
            }}
          />
        </div>
      </Space>
    </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import { addLocationApi, regionLocationsApi } from "../../api/locationAPI";

const initialState = {
  locations: [],
  loading: false,
  selectedProject: null,
  isLocationModalOpen: false,
  selectedLocation: null,
};

export const fetchRegionLocationsAsync = createAsyncThunk(
  "location/fetch",
  async (regionCode, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await regionLocationsApi(regionCode);
      console.log(response);

      // The value we return becomes the `fulfilled` action payload

      return fulfillWithValue({ locations: response?.data });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);
export const submitLocationAsync = createAsyncThunk(
  "location/add",
  async (
    { eskanIranCode, newLocation, date },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await addLocationApi({
        eskanIranCode,
        newLocation,
        date,
      });

      // The value we return becomes the `fulfilled` action payload

      return fulfillWithValue({ response: response.data });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    handleLocationModal: (state, action) => {
      state.isLocationModalOpen = action.payload;
    },
    closeLocationModal: (state) => {
      state.isLocationModalOpen = false;
    },
    handleSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    handleSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegionLocationsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRegionLocationsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.locations = action.payload;
        }
        state.loading = false;
      })
      .addCase(fetchRegionLocationsAsync.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(submitLocationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitLocationAsync.fulfilled, (state, action) => {
        if (action.payload) {
          // state.locations = action.payload;
        }
        state.loading = false;
      })
      .addCase(submitLocationAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectLocationModal = (state) =>
  state.location.isLocationModalOpen;
export const selectLoading = (state) => state.location?.loading;
export const selectRegionLocations = (state) =>
  state.location?.locations.locations;
export const selectSelectedProject = (state) => state.location?.selectedProject;
export const selectSelectedLocation = (state) =>
  state.location?.selectedLocation;

export const {
  handleLocationModal,
  closeLocationModal,
  handleSelectedProject,
  handleSelectedLocation,
} = locationSlice.actions;
export default locationSlice.reducer;

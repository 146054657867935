export function getDateDiffDays(dateStr1, dateStr2) {
  if (dateStr1 === "0" || dateStr2 === "0") return true;
  const date1 = new Date(
    dateStr1.slice(0, 4),
    parseInt(dateStr1.slice(4, 6)) - 1,
    dateStr1.slice(6)
  );

  const date2 = new Date(
    dateStr2.slice(0, 4),
    parseInt(dateStr2.slice(4, 6)) - 1,
    dateStr2.slice(6)
  );

  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays > 7;
}

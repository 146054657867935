import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectProjectDetails } from "../../../store/projects/projectsSlice";

export default function CancelationStatus() {
  const projectDetails = useSelector(selectProjectDetails);

  return (
    <div className="rtl mt-16 bg-[#e2e1e1] flex flex-col gap-5 items-start py-4 px-4 rounded-bl-3xl  relative">
      <Row gutter={56}>
        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-60  text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ درخواست ابطال
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-96 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.requestedCancellationDate
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-60 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              تاریخ ابطال
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-96 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.cancellationDate
                ?.toString()
                .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={56}>
        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-60 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مبلغ مصوب درخواست ابطال
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-96 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.approvedRequestedCancellationAmount}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-60 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مبلغ مصوب ابطال شده
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-96 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.approvedCancellationAmount}
            </div>
          </div>
        </Col>
      </Row>
      <span className="bg-[#D4D1DD] font-bold py-[2px] px-9 rounded-tr-3xl text-#14014B text-lg absolute top-[-31px] right-0">
        وضعیت ابطال پروژه
      </span>
    </div>
  );
}

import { jwtAxios } from ".";

export const PROFILE_API_URL = `/profile`;
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function profileApi() {
  // return jwtAxios.get(BASE_URL + PROFILE_API_URL);
  return jwtAxios.get(BASE_URL + PROFILE_API_URL);
}

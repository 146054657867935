import { Card, Col, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { selectLoading, selectRegion } from "../../../store/region/regionSlice";
import styles from "./styles/regionInfo.module.scss";

export default function RegionInfo() {
  const regionData = useSelector(selectRegion);
  const loading = useSelector(selectLoading);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 10,
      }}
    >
      <Row gutter={[48, 0]}>
        <Col span={8}>
          <Card
            style={{
              height: "50px",
              padding: "0px 0px",
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px 5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              backgroundColor: "#B9B4C7",
              direction: "rtl",
            }}
            bodyStyle={{
              padding: 3,
            }}
          >
            <div className={styles.smallCard}>
              <span>منطقه:</span>
              <span
                className={styles.value}
                style={{ fontSize: 24, marginTop: -8 }}
              >
                {regionData ? (
                  regionData?.targetRegion
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ width: 50, height: 15 }}
                  />
                )}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              height: "50px",
              padding: "0px 0px",
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px 5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              backgroundColor: "#E7E6E6",
              direction: "rtl",
            }}
            bodyStyle={{
              padding: 3,
            }}
          >
            <div className={styles.smallCard}>
              <span>تعداد پروژه ها:</span>
              <span
                className={styles.value}
                style={{ fontSize: 26, marginTop: -8 }}
              >
                {regionData ? (
                  regionData?.projectQnt
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ width: 50, height: 15 }}
                  />
                )}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              height: "50px",
              padding: "0px 0px",
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px 5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              backgroundColor: "#E7E6E6",
              direction: "rtl",
            }}
            bodyStyle={{
              padding: 3,
            }}
          >
            <div className={styles.smallCard}>
              <span>جمعیت منطقه:</span>
              <div
                className={styles.value}
                style={{
                  display: "flex",
                  gap: 5,
                  alignItems: "center",
                  alignSelf: "end",
                  paddingLeft: "5px",
                }}
              >
                {regionData ? (
                  <>
                    <span style={{ fontSize: 24, marginTop: -8 }}>
                      {regionData?.population}
                    </span>
                    <span style={{ fontSize: "13px", marginTop: -8 }}>نفر</span>
                  </>
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ width: 50, height: 15 }}
                  />
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[48, 0]}>
        <Col span={8}>
          <Card
            style={{
              height: "50px",
              padding: "0px 0px",
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px 5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              backgroundColor: "#E7E6E6",
              direction: "rtl",
            }}
            bodyStyle={{
              padding: 3,
            }}
          >
            <div className={styles.smallCard}>
              <span>استان:</span>
              <span
                className={styles.value}
                style={{ fontSize: 18, marginTop: -8 }}
              >
                {regionData ? (
                  regionData?.province
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ width: 50, height: 15 }}
                  />
                )}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              height: "50px",
              padding: "0px 0px",
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px 5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              backgroundColor: "#E7E6E6",
              direction: "rtl",
            }}
            bodyStyle={{
              padding: 3,
            }}
          >
            <div className={styles.smallCard}>
              <span>اعتبار پروژه ها:</span>
              <div
                className={styles.value}
                style={{
                  display: "flex",
                  gap: 5,
                  alignSelf: "end",
                }}
              >
                {regionData ? (
                  <div style={{ marginTop: -8 }}>
                    <span
                      style={{
                        fontSize: "24px",
                        alignSelf: "end",
                      }}
                    >
                      {regionData?.totalCredit}
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        alignSelf: "start",
                        paddingLeft: "5px",
                      }}
                    >
                      میلیارد ریال
                    </span>
                  </div>
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ width: 50, height: 15 }}
                  />
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              height: "50px",
              padding: "0px 0px",
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px 5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              backgroundColor: "#E7E6E6",
              direction: "rtl",
            }}
            bodyStyle={{
              padding: 3,
            }}
          >
            <div className={styles.smallCard}>
              <span>تاریخ اولین ابلاغ:</span>
              <div
                className={styles.value}
                style={{
                  fontSize: 22,
                  marginTop: -8,
                  alignSelf: "end",
                  paddingLeft: 8,
                }}
              >
                {regionData ? (
                  regionData?.firstNotificationDate
                    .toString()
                    .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ width: 50, height: 15 }}
                  />
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

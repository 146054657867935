import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { projectDetailsApi, projectsApi } from "../../api/projectsAPI";
import { isAxiosError } from "axios";

const initialState = {
  projects: [],
  projectDetails: {},
  projectLinks: {},
  loading: false,
};

export const projectsAsync = createAsyncThunk(
  "projects",
  async (regionCode, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await projectsApi(regionCode);

      // The value we return becomes the `fulfilled` action payload
      return fulfillWithValue({ projects: response?.data.projectData });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);
export const projectDetailsAsync = createAsyncThunk(
  "projects/details",
  async (eskanIranCode, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await projectDetailsApi(eskanIranCode);

      // The value we return becomes the `fulfilled` action payload
      return fulfillWithValue({
        projectDetails: response?.data.projectDetails,
        projectLinks: response?.data.projectLinks,
      });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(projectsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(projectsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.projects = action.payload.projects;
        }
        state.loading = false;
      })
      .addCase(projectsAsync.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(projectDetailsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(projectDetailsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.projectDetails = action.payload.projectDetails;
          state.projectLinks = action.payload.projectLinks;
        }
        state.loading = false;
      })
      .addCase(projectDetailsAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectLoading = (state) => state.projects?.loading;
export const selectProjects = (state) => state.projects?.projects;
export const selectProjectDetails = (state) => state.projects?.projectDetails;
export const selectProjectLinks = (state) => state.projects?.projectLinks;

export default projectsSlice.reducer;

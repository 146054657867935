import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginApi } from "../../api/authAPI";
import { getLocalStorage, setLocalStorage } from "../../helpers/index";

const initialState = {
  isAuthenticated: !!getLocalStorage("isAuthenticated", false),
  user: null,
  loading: false,
};

// export const useAsyncLogout = false;

export const loginAsync = createAsyncThunk(
  "auth/login",
  async ({ username, password }) => {
    try {
      const response = await loginApi(username, password);
      // The value we return becomes the `fulfilled` action payload
      if (response) return response.data;
    } catch (err) {
      if (err.response.status === 404) {
        console.log(err.response.status);
        return;
      }
    }
  }
);

// export const logoutAsync = createAsyncThunk("auth/logout", async () => {
//   const response = await logoutApi();
//   return response.data;
// });

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    syncState: (state) => {
      state.user = getLocalStorage("user", null);
      state.isAuthenticated = getLocalStorage("isAuthenticated", false);
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (action.payload) {
          setLocalStorage("isAuthenticated", true);
          setLocalStorage("token", action.payload.user.token);
          state.isAuthenticated = true;
          state.user = action.payload.user;
        }
        state.loading = false;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.loading = false;
      });
    // .addCase(logoutAsync.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(logoutAsync.fulfilled, (state, action) => {
    //   state.isAuthenticate = true;
    //   state.user = action.payload;
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("isAuthenticate");
    //   state.loading = false;
    //   window.location.href = "/";
    // })
    // .addCase(logoutAsync.rejected, (state) => {
    //   state.loading = false;
    // });
  },
});

export const selectAuth = (state) => state.auth;
export const selectToken = (state) => state.auth.user?.token;
export const selectLoading = (state) => state.auth.loading;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
// export const selectUser = (state) => state.auth.user;

export const { syncState, logout } = authSlice.actions;
export default authSlice.reducer;

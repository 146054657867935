import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { regionApi } from "../../api/regionAPI";
import { isAxiosError } from "axios";

const initialState = {
  region: null,
  loading: false,
  selectedRegion: null,
};

// export const useAsyncLogout = false;

export const regionAsync = createAsyncThunk(
  "region",
  async (regionCode, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await regionApi(regionCode);

      // The value we return becomes the `fulfilled` action payload

      return fulfillWithValue({ region: response?.data.region });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    handleSelectRegion: (state, action) => {
      state.selectedRegion = state.selectedRegion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(regionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(regionAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.region = action.payload.region;
        }
        state.loading = false;
      })
      .addCase(regionAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

// export const selectAuth = (state) => state.auth;
// export const selectToken = (state) => state.auth.user?.token;
export const selectLoading = (state) => state.region?.loading;
// export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectRegion = (state) => state.region?.region;
export const selectSelectedRegion = (state) => state.region?.selectedRegion;

export const { handleSelectRegion } = regionSlice.actions;
export default regionSlice.reducer;

import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectProjectDetails } from "../../../store/projects/projectsSlice";

export default function SubjectClassification() {
  const projectDetails = useSelector(selectProjectDetails);

  return (
    <div className="rtl mt-16 bg-[#e2e1e1] flex flex-col gap-5 items-start py-4 px-4 rounded-bl-3xl  relative">
      <Row gutter={76}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              موضوع کلی
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.generalSubject}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              نوع پروژه
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.typeOfProject}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مقدار کل
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.totalAmount}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={76}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              موضوع جزئی
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.detailedSubject}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32  text-[16px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              دسته بندی ریالی
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.moneyClassification}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-[16px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              مقدار انجام شده
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.completedAmount}
            </div>
          </div>
        </Col>
      </Row>
      <span className="bg-[#D4D1DD] font-bold py-[2px] px-9 rounded-tr-3xl text-#14014B text-lg absolute top-[-31px] right-0">
        طبقه بندی موضوعی و ریالی پروژه
      </span>
    </div>
  );
}

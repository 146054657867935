export function validateJwtStructure(token) {
  const parts = token.split(".");

  if (parts.length !== 3) {
    return false;
    // throw new Error("Invalid JWT. Must contain 3 parts");
  }

  parts.forEach((part) => {
    if (!isBase64Url(part)) {
      return false;
      //   throw new Error("Invalid base64 encoding");
    }
  });

  return true;
}

function isBase64Url(str) {
  return /^[A-Za-z0-9\-_]+=*$/.test(str);
}

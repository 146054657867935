import NotificationsHeader from "../../components/Notifications/NotificationsHeader";

const NotificationsPage = () => {
  return (
    <>
      <NotificationsHeader />
    </>
  );
};

export default NotificationsPage;

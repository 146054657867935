import { useEffect, useRef } from "react";
import {
  ProjectOutlined,
  DashboardOutlined,
  FormOutlined,
  UserOutlined,
  LogoutOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  FundProjectionScreenOutlined,
  AimOutlined,
  CloudUploadOutlined,
  NotificationOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Avatar, Divider, Select } from "antd";
import styles from "../../styles/layout.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, getLocalStorage } from "../../helpers";
import { logout } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  profileAsync,
  selectUser,
  selectLoading,
} from "../../store/profile/profileSlice";
import { Skeleton } from "antd";
import { useState } from "react";
import {
  handleSelectRegion,
  regionAsync,
} from "../../store/region/regionSlice";

const { Sider } = Layout;

const items = [
  getItem(
    "داشبورد کاربری",
    "/dashboard",
    <DashboardOutlined className={styles.icon} />
  ),
  getItem(
    "لیست پروژه ها",
    "/projects",
    <ProjectOutlined className={styles.icon} />
  ),
  getItem(
    "ثبت پیشرفت پروژه ها",
    "/progress",
    <FundProjectionScreenOutlined className={styles.icon} />
  ),
  getItem(
    "ثبت موقعیت مکانی پروژه ها",
    "/location",
    <AimOutlined className={styles.icon} />
  ),
  getItem(
    "بارگذاری تصاویر پروژه ها",
    "/upload",
    <CloudUploadOutlined className={styles.icon} />
  ),
  getItem("گزارش ها", "/reports", <FormOutlined className={styles.icon} />),
  getItem(
    "اطلاعیه ها",
    "/notifications",
    <NotificationOutlined className={styles.icon} />
  ),
  getItem("پیگیری ها", "/follow-up", <MailOutlined className={styles.icon} />),
  getItem("خروج", "/login", <LogoutOutlined className={styles.icon} />),
];

export default function SideBar({ collapsed, setCollapsed }) {
  const token = getLocalStorage("token", null)
    ? getLocalStorage("token", null)
    : null;
  const dispatch = useDispatch();
  const sidebarRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const defaultRegion = user?.regionInfo[0]?.regionCode ?? "";
  const [selectedRegion, setSelectedRegion] = useState(defaultRegion);

  const handleNavLink = (event) => {
    if (event.key === "/login") {
      localStorage.clear();
      logout();
      window.location.href = "/login";
    }
    navigate(event.key);
  };

  //handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200 && !collapsed) {
        setCollapsed(true);
      }
      if (window.innerWidth >= 1200 && collapsed) {
        setCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    //clean up
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  // close side bar by clicking on the screen
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!sidebarRef.current.contains(event.target)) {
        if (window.innerWidth < 575) {
          setCollapsed(true);
        }
      }
    };
    document.addEventListener("click", handleOutsideClick);
    //clean up
    return () => document.removeEventListener("click", handleOutsideClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get user data
  useEffect(() => {
    dispatch(profileAsync());
  }, [dispatch, token]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // selected region effect
  const handleRegionChange = (regionCode) => {
    setSelectedRegion(regionCode);
  };
  useEffect(() => {
    if (selectedRegion) {
      dispatch(handleSelectRegion(selectedRegion));
      dispatch(regionAsync(selectedRegion));
    }
  }, [selectedRegion, dispatch]);

  useEffect(() => {
    if (user?.regionInfo?.[0]?.regionCode) {
      setSelectedRegion(user.regionInfo[0].regionCode);
    }
  }, [user]);

  return (
    <div style={{ height: "100vh" }}>
      <Sider
        className={`${styles.sider} ${!collapsed && styles.active}`}
        ref={sidebarRef}
      >
        <DoubleLeftOutlined
          className={`${styles.doubleLeft} ${collapsed ? "" : styles.hide}`}
          onClick={toggleCollapsed}
        />
        <div
          className={`${styles.avatarContainer} ${!collapsed && styles.active}`}
        >
          <Avatar size={64} icon={<UserOutlined />} className={styles.avatar} />
          <div className={styles.siderTop}>
            <div className={styles.avatarNameSection}>
              <span
                className={styles.avatarName}
                style={{ width: !user ? 100 : "" }}
              >
                {user ? (
                  user && user.name
                ) : (
                  <Skeleton.Input
                    size={15}
                    active
                    loading={loading}
                    style={{
                      backgroundColor: "rgb(234 234 234)",
                      borderRadius: "unset",
                    }}
                  />
                )}
              </span>
              <span
                className={styles.avatarRegion}
                style={{ width: !user ? 80 : "" }}
              >
                {user !== null && user.regionInfo.length > 0 ? (
                  <Select
                    bordered={false}
                    dropdownStyle={{
                      whiteSpace: "normal",
                      textOverflow: "clip",
                      direction: "rtl",
                      width: "140px",
                    }}
                    optionLabelProp="label"
                    onChange={handleRegionChange}
                    options={
                      user &&
                      user.regionInfo.map((re) => ({
                        label: re.regionName,
                        value: re.regionCode,
                      }))
                    }
                    defaultValue={
                      selectedRegion || user?.regionInfo[0]?.regionCode
                    }
                  ></Select>
                ) : (
                  <Skeleton.Input
                    size={15}
                    active
                    loading={loading}
                    style={{
                      backgroundColor: "rgb(234 234 234)",
                      borderRadius: "unset",
                    }}
                  />
                )}
              </span>
            </div>
          </div>
          <DoubleRightOutlined
            className={`${styles.doubleRight} ${collapsed ? styles.hide : ""}`}
            onClick={toggleCollapsed}
          />
        </div>
        <Divider
          className={`${styles.divider} ${collapsed ? styles.hide : ""}`}
        />
        <Menu
          style={{ border: "none", background: "none" }}
          onClick={handleNavLink}
          mode="inline"
          selectedKeys={[location.pathname]}
          items={items}
          className={styles.sidebarItems}
        />
      </Sider>
    </div>
  );
}

import { Col, Empty, Layout, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import jalaliMoment from "jalali-moment";
import {
  projectsAsync,
  selectLoading,
  selectProjects,
} from "../../store/projects/projectsSlice";
import ProgressModal from "../../components/Modals/ProgressModal";
import SuspendModal from "../../components/Modals/SuspendModal";
import ProjectCard from "../../components/Progress/ProjectCard";
import "../../components/Progress/css/style.css";
import { getDateDiffDays } from "../../components/Progress/helpers/dateDiff";
import { useEffect } from "react";
import { selectSelectedRegion } from "../../store/region/regionSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { selectUser } from "../../store/profile/profileSlice";
// import PersianDate from "persian-date";
// import {
//   progressAsync,
//   selectLoading,
// } from "../../store/progress/progressSlice";
// import { ToastContainer, toast } from "react-toastify";

export default function ProgressPage() {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const loading = useSelector(selectLoading);
  const selectedRegion = useSelector(selectSelectedRegion);

  const persianDate = jalaliMoment().format("jYYYYjMMjDD");

  // fetch projects
  useEffect(() => {
    dispatch(projectsAsync(selectedRegion));
  }, [dispatch, selectedRegion]);

  // sorted projects
  const codeRegex = /([a-zA-Z]+)(\d+)/;

  const sortedProjects =
    projects &&
    projects
      .map((project, index) => ({ ...project, key: index }))
      .sort((a, b) => {
        const codeA = a.eskanIranCode.match(codeRegex);
        const codeB = b.eskanIranCode.match(codeRegex);

        const lettersA = codeA[1];
        const lettersB = codeB[1];

        if (lettersA < lettersB) return -1;
        if (lettersA > lettersB) return 1;

        const numA = parseInt(codeA[2]);
        const numB = parseInt(codeB[2]);

        if (numA < numB) return -1;
        if (numA > numB) return 1;

        return 0;
      });

  return (
    <Layout>
      <div className="custom-spin">
        <Spin
          spinning={loading}
          style={{ height: "100vh", backgroundColor: "rgb(242, 242, 242)" }}
        >
          {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
          <Row
            gutter={[{ xs: 8, sm: 16, md: 1, lg: 24, xl: 24 }, 16]}
            className="rtl"
          >
            {sortedProjects.map((project) => {
              const diffDays = getDateDiffDays(
                project.lastUpdateDate,
                persianDate
              );
              if (
                ["پیش اجرا", "جاری", "متوقف"].includes(project.executiveStatus)
              ) {
                return (
                  <Col
                    key={project.eskanIranCode}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={6}
                  >
                    <ProjectCard project={project} diffDays={diffDays} />
                  </Col>
                );
              }
              return null;
            })}
          </Row>

          {sortedProjects.filter((project) => {
            return ["پیش اجرا", "جاری", "متوقف"].includes(
              project.executiveStatus
            );
          }).length === 0 && (
            <div className="flex items-center justify-center h-full">
              <div className="text-center rtl">
                <Empty
                  description="پروژه ایی یافت نشد!"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 60,
                  }}
                />
                <div className="text-purple-800 text-lg mt-5 rtl">
                  پروژه ایی برای ثبت پیشرفت در این منطقه وجود ندارد.
                </div>
              </div>
            </div>
          )}
        </Spin>
      </div>
      <ProgressModal />
      <SuspendModal />
    </Layout>
  );
}

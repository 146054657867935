import { Modal, Form, Spin, InputNumber, Button, Row, Col } from "antd";
import jalaliMoment from "jalali-moment";
import { Datepicker } from "@ijavad805/react-datepicker";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  closeProgressModal,
  progressAsync,
  selectExecutionStatus,
  selectLoading,
  selectPhysicalProgress,
  selectProgressModal,
  selectProjectCode,
} from "../../store/progress/progressSlice";
import { useCallback, useState } from "react";

export default function ProgressModal() {
  const dispatch = useDispatch();
  const [progressForm] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isCompletionDateVisible, setIsCompletionDateVisible] = useState(false);
  const [isStartDateVisible, setIsStartDateVisible] = useState(false);
  const [selectedFinalDate, setSelectedFinalDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [photos, setPhotos] = useState([]);
  const loading = useSelector(selectLoading);
  const selectedProjectCode = useSelector(selectProjectCode);
  const isModalOpen = useSelector(selectProgressModal);
  const lastProgress = useSelector(selectPhysicalProgress);
  const executionStatus = useSelector(selectExecutionStatus);

  const restrictInput = (value) => {
    // Allow only numbers
    return value.replace(/[^0-9]/g, "");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // handle success error on submiting progress form
  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    onClose: () => dispatch(closeProgressModal()),
  };
  //
  const handleSuccess = () => {
    toast.success("اطلاعات پیشرفت پروژه با موفقیت ثبت شد.", toastOptions);
    progressForm.resetFields();
    setSubmitLoading(false);
    setPhotos([]);
  };

  const handlePayloadTooLargeError = () => {
    toast.error(
      "خطا! سایز هر عکس میبایست کمتر از 20 کیلوبایت باشد.",
      toastOptions
    );
  };

  const handleOtherError = () => {
    toast.error(
      "خطایی رخ داده است مجدد در زمان دیگری اطلاعات را ثبت نمایید.",
      toastOptions
    );
  };
  const onFinish = async (values) => {
    setSubmitLoading(true);
    // Get the current date in Persian (Jalali)
    const persianDate = jalaliMoment().format("jYYYYjMMjDD");
    const data = {
      projectCode: selectedProjectCode,
      description: values.description,
      progress: `${values.progress}%`,
      date: persianDate,
      finalDate: selectedFinalDate,
      startDate: selectedStartDate,
      photos: photos,
    };
    dispatch(progressAsync(data)).then((action) => {
      if (action.payload.statusCode === 200) {
        handleSuccess();
      } else {
        if (
          action.payload.message === "Request too large" ||
          action.payload.message === "Bad Request"
        ) {
          handlePayloadTooLargeError();
        } else {
          handleOtherError();
        }
        progressForm.resetFields();
        setSubmitLoading(false);
        setPhotos([]);
      }
    });
  };

  const handleProgressChange = (value) => {
    const numericValue = parseFloat(value);
    setIsCompletionDateVisible(!isNaN(numericValue) && numericValue === 100);
    if (executionStatus === "پیش اجرا") {
      setIsStartDateVisible(!isNaN(numericValue) && numericValue > 0);
    }
    if (executionStatus === "متوقف" && lastProgress === "0%") {
      setIsStartDateVisible(!isNaN(numericValue));
    }
  };

  const onDateChangeFinal = (date) => {
    // Input Gregorian date
    const gregorianDate = date;
    // Convert to Persian (Jalali) date
    const persianDate = jalaliMoment(gregorianDate).format("jYYYYjMMjDD");
    setSelectedFinalDate(persianDate);
  };
  const onDateChangeStart = (date) => {
    // Input Gregorian date
    const gregorianDate = date;
    // Convert to Persian (Jalali) date
    const persianDate = jalaliMoment(gregorianDate).format("jYYYYjMMjDD");
    setSelectedStartDate(persianDate);
  };

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const newPhotos = new Set();

      for (let file of acceptedFiles) {
        const base64 = await toBase64(file);

        newPhotos.add(base64);
      }

      // Merge new photos set with current state set
      const mergedPhotos = new Set([...photos, ...newPhotos]);

      setPhotos(Array.from(mergedPhotos));
    },
    [photos]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    // maxFiles: 1,
  });

  return (
    <Modal
      className="rtl"
      title="پنجره ثبت پیشرفت"
      open={isModalOpen}
      onCancel={() => {
        progressForm.resetFields();
        setIsCompletionDateVisible(false);
        setIsStartDateVisible(false);
        setPhotos([]);
        dispatch(closeProgressModal());
      }}
      footer={false}
    >
      <Spin spinning={loading}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Form
          labelCol={{
            span: 8.5,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            direction: "rtl",
          }}
          form={progressForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h3 style={{ marginBottom: 10 }}>
            <span>پروژه:</span>
            <span className="mr-1 font-bold">{selectedProjectCode}</span>
          </h3>
          <h3 style={{ marginBottom: 10 }}>
            <span>درصد پیشرفت کنونی:</span>
            <span className="mr-1 font-bold">{lastProgress}</span>
          </h3>
          <Form.Item
            label="درصد پیشرفت را وارد نمایید:"
            name="progress"
            rules={[
              {
                type: "number",
                required: true,
                min: 1,
                max: 100,
                message: "درصد پیشرفت باید بین 1 تا 100 باشد.",
              },
              {
                validator: async (rule, value) => {
                  // Convert the lastProgress to a number
                  const lastProgressNumber = parseFloat(
                    lastProgress.replace("%", "")
                  );
                  // Check if the value is greater than or equal to the last progress
                  if (value >= lastProgressNumber + 1) {
                    // Call the function to handle progress change
                    handleProgressChange(value);
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "درصد پیشرفت نباید از مقدار کنونی کمتر باشد."
                  );
                },
              },
            ]}
          >
            <InputNumber formatter={restrictInput} parser={restrictInput} />
          </Form.Item>
          {isCompletionDateVisible && (
            <Form.Item
              label="تاریخ اتمام پروژه:"
              name="completionDate"
              rules={[
                {
                  required: true,
                  message: "لطفاً تاریخ اتمام پروژه را وارد کنید.",
                },
              ]}
            >
              <Datepicker
                footer={function noRefCheck() {}}
                format="YYYY-MM-DD"
                disabledDate={(day) => day > moment()} // till yesterday should be disabled
                input={<input placeholder="تاریخ را وارد نمایید" />}
                lang="fa"
                modeTheme="light"
                theme="blue"
                onChange={(val) => {
                  onDateChangeFinal(val);
                }}
              />
            </Form.Item>
          )}
          {isStartDateVisible && (
            <Form.Item
              label="تاریخ شروع:"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: "لطفاً تاریخ شروع را وارد کنید.",
                },
              ]}
            >
              <Datepicker
                footer={function noRefCheck() {}}
                format="YYYY-MM-DD"
                disabledDate={(day) => day > moment()} // till yesterday should be disabled
                input={<input placeholder="تاریخ را وارد نمایید" />}
                lang="fa"
                modeTheme="light"
                theme="blue"
                onChange={(val) => {
                  onDateChangeStart(val);
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            label="شرح مختصری از پیشرفت"
            name="description"
            rules={[
              {
                required: true,
                message: "توضیح در مورد پروژه لازم است.",
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder="توضیحی در ارتباط با میزان پیشرفت پروژه بنویسید..."
            />
          </Form.Item>
          <Form.Item label="تصویر پیشرفت پروژه را بارگذاری کنید:" name="photo">
            <div>
              <div
                {...getRootProps()}
                className="border-2 border-dashed border-gray-400 p-4 text-center flex flex-col items-center"
              >
                <input {...getInputProps({ accept: "image/*" })} />
                عکس را اینجا رها کنید و یا
                <p className="bg-gradient-to-r from-purple-900 to-blue-700 text-white p-1 rounded-xl w-24 text-center hover:cursor-pointer mt-2">
                  انتخاب کنید
                </p>
              </div>
              <h5 className="text-red-500 text-[11px] mt-1">
                سایز هر عکس میبایست کمتر از 20 کیلو بایت باشد.
              </h5>
            </div>
          </Form.Item>
          {photos.length > 0 && (
            <Row gutter={[6, 6]} style={{ marginBottom: 10 }}>
              {[...new Set(photos)].map((photo) => (
                <Col flex="auto" key={photo}>
                  <img
                    key={photo}
                    alt="Preview"
                    src={photo}
                    style={{
                      width: "100%",
                      maxWidth: "150px",
                      maxHeight: "100px",
                      marginTop: "10px",
                    }}
                  />
                </Col>
              ))}
            </Row>
          )}
          <Form.Item>
            <Button
              className="bg-indigo-900"
              type="primary"
              htmlType="submit"
              disabled={submitLoading}
            >
              ثبت
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

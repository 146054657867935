import ProjectDetails from "../../components/Projects/ProjectDetails";

const ProjectDetailsPage = () => {
  return (
    <>
      <ProjectDetails />
    </>
  );
};

export default ProjectDetailsPage;

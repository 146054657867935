import { jwtAxios } from ".";

export const MAP_API_URL = `/map`;
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function mapApi() {
  return jwtAxios.get(BASE_URL + MAP_API_URL);
}

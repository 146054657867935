import { jwtAxios } from ".";

export const REGION_API_URL = "/region";
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function regionApi(regionCode) {
  return jwtAxios.get(BASE_URL + REGION_API_URL, {
    params: {
      regionCode,
    },
  });
}

import { Spin } from "antd";
import {
  DashboardRegionSection,
  // DashboardHeader,
  DashboardTop,
  // LeafletMap,
} from "../../components/Dashboard/index";
import { useSelector } from "react-redux";
import { selectLoading } from "../../store/region/regionSlice";
import { Helmet } from "react-helmet";
import DashboardBottom from "../../components/Dashboard/DashboardBottom";

const DashboardPage = () => {
  const loading = useSelector(selectLoading);
  return (
    <>
      <Helmet>
        <title>داشبورد کاربری | سامانه پروژه های اسکان ایران</title>
      </Helmet>
      <Spin spinning={loading}>
        <DashboardTop />
        <DashboardRegionSection />
        <DashboardBottom />
      </Spin>
    </>
  );
};

export default DashboardPage;

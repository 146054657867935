import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
//components
import SideBar from "./SideBar";
//images
import EskanIranLogo from "../../asset/images/logo.png";
//styles
import styles from "../../styles/layout.module.scss";

const { Header, Content, Footer } = Layout;

const PanelLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className={styles.layout}>
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout
        className={`${styles.secondaryLayout} ${!collapsed && styles.active}`}
      >
        <Header className={styles.header}>
          <img src={EskanIranLogo} alt="logo" />
          <span>سامانه جامع مدیریت پروژه های شرکت اسکان ایران</span>
        </Header>

        <Content className={styles.content}>
          <Outlet />
        </Content>
        <Footer className={styles.footer}>
          کلیه حقوق این وب‌سایت محفوظ و در اختیار شرکت اسکان ایران است.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default PanelLayout;

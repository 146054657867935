import { Card, Col, Progress, Row, Skeleton, Space } from "antd";
import styles from "./styles/DashHeader.module.scss";

import { useSelector } from "react-redux";
import { selectLoading } from "../../store/profile/profileSlice";
import { selectRegion } from "../../store/region/regionSlice";
import NumberAnimation from "../shared/NumberAnimation";

export default function DashboardTop() {
  const loading = useSelector(selectLoading);
  const regionData = useSelector(selectRegion);
  const creditInHands = regionData?.creditInHands.replace(",", "");
  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Row
        justify="space-between"
        className={styles.DashboardTop}
        gutter={24}
        style={{ margin: "10px 0px 40px 0px" }}
      >
        <Col span={24 / 5}>
          <Card
            style={{
              backgroundColor: "#D4D1DD",
              height: 90,
              width: 180,
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)",
              border: "none",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 12,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
                direction: "rtl",
              }}
            >
              پروژه های دارای تصویر بارگذاری شده
              <span
                style={{
                  color: "#14014B",
                  fontSize: 24,
                  fontWeight: 600,
                  paddingLeft: 3,
                }}
              >
                {regionData ? (
                  <Progress
                    type="dashboard"
                    gapDegree={180}
                    percent={regionData && regionData?.projectIncPhoto}
                    size={[100, 100]}
                    strokeWidth={18}
                    strokeColor="#14014B"
                    trailColor="#fff"
                    strokeLinecap="butt"
                  />
                ) : (
                  <Skeleton.Input size={15} active loading={loading} />
                )}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={24 / 5}>
          <Card
            style={{
              backgroundColor: "#D4D1DD",
              height: 90,
              width: 180,
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)",
              border: "none",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 12,
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
                direction: "rtl",
              }}
            >
              <span
                style={{
                  color: "#14014B",
                  fontSize: 30,
                  fontWeight: 600,
                  paddingLeft: 3,
                }}
              >
                {regionData ? (
                  regionData &&
                  regionData?.lastUpdateDate
                    .toString()
                    .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")
                ) : (
                  <Skeleton.Input size={15} active loading={loading} />
                )}
              </span>
              تاریخ آخرین بروزرسانی
            </span>
          </Card>
        </Col>
        <Col span={24 / 5}>
          <Card
            style={{
              backgroundColor: "#D4D1DD",
              height: 90,
              width: 180,
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)",
              border: "none",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 12,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
                direction: "rtl",
              }}
            >
              درصد موقعیت های مکانی ثبت شده
              <span
                style={{
                  color: "#14014B",
                  fontSize: 24,
                  fontWeight: 600,
                  paddingLeft: 3,
                }}
              >
                {regionData ? (
                  <Progress
                    type="dashboard"
                    percent={regionData && regionData?.registeredLocation}
                    size={[100, 100]}
                    strokeWidth={18}
                    strokeColor="#14014B"
                    trailColor="#fff"
                    strokeLinecap="butt"
                    gapDegree={180}
                  />
                ) : (
                  <Skeleton.Input size={15} active loading={loading} />
                )}
              </span>
            </div>
          </Card>
        </Col>
        <Row gutter={12} span={(24 / 5) * 2}>
          <Col>
            <Card
              style={{
                direction: "rtl",
                backgroundColor: "#D4D1DD",
                height: 90,
                width: 180,
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              size="default"
              headStyle={{
                borderBottom: "unset",
                fontSize: 14,
                fontWeight: 500,
              }}
              bodyStyle={{
                padding: 2,
              }}
            >
              <div className="text-center">میزان اعتبار</div>
              <div className="text-left">
                <span
                  style={{
                    color: "#14014B",
                    fontSize: 38,
                    fontWeight: 600,
                    paddingLeft: 3,
                  }}
                  className="text-center"
                >
                  {regionData ? (
                    <NumberAnimation
                      endValue={+creditInHands}
                      duration={1000}
                    />
                  ) : (
                    <Skeleton.Input
                      size="small"
                      active
                      loading={loading}
                      style={{ height: 15 }}
                    />
                  )}
                </span>
                <span className="text-xs">میلیارد ریال</span>
              </div>
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                backgroundColor: "#D4D1DD",
                height: 90,
                width: 180,
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              size="default"
              headStyle={{
                borderBottom: "unset",
                fontSize: 14,
                fontWeight: 500,
              }}
              bodyStyle={{
                padding: 2,
              }}
            >
              <div className="text-center">پروژه در دستور کار</div>
              <div
                style={{
                  color: "#14014B",
                  fontSize: 38,
                  fontWeight: 600,
                  paddingLeft: 3,
                }}
                className="text-center"
              >
                {regionData ? (
                  <NumberAnimation
                    endValue={+regionData?.projectInHands}
                    duration={1000}
                  />
                ) : (
                  <Skeleton.Input
                    size="small"
                    active
                    loading={loading}
                    style={{ height: 15 }}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Row>
    </Space>
  );
}

import FollowUpHeader from "../../components/FollowUp/FollowUpHeader";

const FollowUpPage = () => {
  return (
    <>
      <FollowUpHeader />
    </>
  );
};

export default FollowUpPage;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { mapApi } from "../../api/mapAPI";

const initialState = {
  regions: [],
  loading: false,
};

// export const useAsyncLogout = false;

export const mapAsync = createAsyncThunk("map", async () => {
  try {
    const response = await mapApi();

    // The value we return becomes the `fulfilled` action payload
    if (response) return response.data;
  } catch (err) {
    if (err.response.status === 404) {
      console.log(err.response.status);
      return;
    }
  }
});

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    // syncState: (state) => {
    //   state.user = getLocalStorage("user", null);
    //   state.isAuthenticated = getLocalStorage("isAuthenticated", false);
    // },
    // logout: (state) => {
    //   state.user = null;
    //   state.isAuthenticated = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(mapAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(mapAsync.fulfilled, (state, action) => {
        if (action.payload) {
          //   setLocalStorage("isAuthenticated", true);
          //   setLocalStorage("token", action.payload.user.token);
          //   state.isAuthenticated = true;
          state.regions = action.payload.result;
        }
        state.loading = false;
      })
      .addCase(mapAsync.rejected, (state) => {
        state.loading = false;
      });
    // .addCase(logoutAsync.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(logoutAsync.fulfilled, (state, action) => {
    //   state.isAuthenticate = true;
    //   state.user = action.payload;
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("isAuthenticate");
    //   state.loading = false;
    //   window.location.href = "/";
    // })
    // .addCase(logoutAsync.rejected, (state) => {
    //   state.loading = false;
    // });
  },
});

// export const selectAuth = (state) => state.auth;
// export const selectToken = (state) => state.auth.user?.token;
export const selectLoading = (state) => state.map.loading;
// export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectMapRegions = (state) => state.map.regions;

// export const { syncState, logout } = authSlice.actions;
export default mapSlice.reducer;

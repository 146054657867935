import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import styles from "../../styles/login.module.scss";
import Logo from "../../asset/images/logo.png";
import { Button, Card, Form, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAsync,
  selectIsAuthenticated,
  selectLoading,
} from "../../store/auth/authSlice";
import { ToastContainer, toast } from "react-toastify";
import { getLocalStorage } from "../../helpers";
import { validateJwtStructure } from "../../helpers/validateToken";

export default function LoginPage() {
  const dispatch = useDispatch();
  const loginLoading = useSelector(selectLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const jwtToken = getLocalStorage("token", null)
    ? getLocalStorage("token", null)
    : null;
  const validToken = jwtToken && validateJwtStructure(jwtToken);

  if (isAuthenticated && validToken) {
    return <Navigate to={"/"}></Navigate>;
  }

  const onFinish = async (values) => {
    dispatch(
      loginAsync({ username: values.username, password: values.password })
    ).then((action) => {
      if (!action.payload) {
        toast("نام کاربری یا رمز عبور اشتباه است!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className={styles.form}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <title>login</title>
      </Helmet>
      <span className={styles.title}>
        سامانه جامع مدیریت پروژه های شرکت اسکان ایران
      </span>
      <Card className={styles.card}>
        <Row justify={"center"}>
          {/* <ContrastLogo style={{ width: 120 }} /> */}
          <img src={Logo} alt="لوگو" />
        </Row>
        <Form
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="نام کاربری یا نشانی ایمیل"
            name="username"
            rules={[
              { required: true, message: "نام کاربری خود را وارد نمایید." },
            ]}
          >
            <Input size="large" className="ltr" />
          </Form.Item>

          <Form.Item
            label="رمزعبور"
            name="password"
            rules={[
              { required: true, message: "رمز عبور خود را وارد نمایید." },
            ]}
          >
            <Input.Password size="large" className="ltr" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loginLoading}
              disabled={loginLoading}
              block
              className={styles.button}
              style={{
                color: loginLoading ? "#000" : "",
              }}
            >
              ورود
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

import { jwtAxios } from ".";

export const PROJECTS_API_URL = `/projects`;
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function projectsApi(regionCode) {
  return jwtAxios.get(BASE_URL + PROJECTS_API_URL, {
    params: {
      regionCode,
    },
  });
}
export function projectDetailsApi(eskanIranCode) {
  return jwtAxios.get(BASE_URL + PROJECTS_API_URL + "/" + eskanIranCode);
}

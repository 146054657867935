import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import profileReducer from "./profile/profileSlice";
import mapReducer from "./map/mapSlice";
import projectsReducer from "./projects/projectsSlice";
import progressSlice from "./progress/progressSlice";
import regionSlice from "./region/regionSlice";
import chartSlice from "./chart/chartSlice";
import locationSlice from "./location/locationSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    map: mapReducer,
    projects: projectsReducer,
    progress: progressSlice,
    region: regionSlice,
    chart: chartSlice,
    location: locationSlice,
  },
});

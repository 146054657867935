import { useRouteError, isRouteErrorResponse, Link } from "react-router-dom";
import { Button, Result } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

export default function ErrorPage() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      // ...
    } else if (error.status === 404) {
      // ...
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to={"/"}>
              <Button
                type="primary"
                style={{ backgroundColor: "#722ed1", color: "#fff" }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#9254de")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#722ed1")
                }
                className="hover:bg-slate-500"
                icon={<RollbackOutlined />}
              >
                Back Home
              </Button>
            </Link>
          }
        />
      );
    } else if (error.status === 403) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Link to={"/"}>
              <Button type="primary" icon={<RollbackOutlined />}>
                Back Home
              </Button>
            </Link>
          }
        />
      );
    } else if (error.status === 500) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Link to={"/"}>
              <Button type="primary" icon={<RollbackOutlined />}>
                Back Home
              </Button>
            </Link>
          }
        />
      );
    }
  }
}

import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { chartAsync, selectCharts } from "../../../store/chart/chartSlice";
import { useEffect } from "react";
import { useState } from "react";
import { selectSelectedRegion } from "../../../store/region/regionSlice";

export default function ProgressChart() {
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [physical, setPhysical] = useState([]);
  const [programmatic, setProgrammatic] = useState([]);
  const selectedRegion = useSelector(selectSelectedRegion);
  const chartData = useSelector(selectCharts);

  useEffect(() => {
    if (selectedRegion) {
      dispatch(chartAsync(selectedRegion));
    }
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    // Find the object with regionCode === "code" to build label array
    if (chartData.length !== 0) {
      const codeObject = chartData?.find((item) => item.regionCode === "code");
      // Extract the month keys from the codeObject
      const monthKeys = Object.keys(codeObject)
        .filter((key) => key.startsWith("month_"))
        .sort((a, b) => parseInt(a.slice(6)) - parseInt(b.slice(6)));
      // Extract the month values from the codeObject
      const formattedMonthValues = monthKeys.map((key) => {
        return codeObject[key]
          .toString()
          .slice(0, 6)
          .replace(/(\d{4})(\d{2})/, "$1/$2");
        //  `${year}/${month}`;
      });
      setLabels(formattedMonthValues);
    }
    // Find the object with regionCode === "ev" to build bar chart (physicalProgress)
    if (chartData.length !== 0) {
      const codeObject = chartData?.find(
        (item) => item.regionCode === "ev" || "EV"
      );
      // Extract the month keys from the codeObject
      const monthKeys = Object.keys(codeObject)
        .filter((key) => key.startsWith("month_"))
        .sort((a, b) => parseInt(a.slice(6)) - parseInt(b.slice(6)));

      const physicalValues = monthKeys.map((key) => codeObject[key]);
      setPhysical(physicalValues);
    }
    // Find the object with regionCode === "pv" to build bar chart (programmaticProgress)
    if (chartData.length !== 0) {
      const codeObject = chartData?.find(
        (item) => item.regionCode === "pv" || "PV"
      );
      // Extract the month keys from the codeObject
      const monthKeys = Object.keys(codeObject)
        .filter((key) => key.startsWith("month_"))
        .sort((a, b) => parseInt(a.slice(6)) - parseInt(b.slice(6)));

      const programmaticValues = monthKeys.map((key) => codeObject[key]);
      setProgrammatic(programmaticValues);
    }
  }, [chartData]);

  const data = {
    labels: labels || [],
    datasets: [
      {
        type: "bar", // Bar chart
        label: "درصد پیشرفت فیزیکی",
        data: physical || [],
        backgroundColor: "rgba(156,149,177,1)",
        borderColor: "rgba(156,149,177,1)",
        borderWidth: 1,
      },
      {
        type: "line", // Line chart
        label: "درصد پیشرفت برنامه ای",
        data: programmatic || [],
        fill: false,
        backgroundColor: "rgba(20, 1, 75, 1)",
        borderColor: "rgba(20, 1, 75, 1)",
        pointRadius: 0, // Set pointRadius to 0 to hide data points on the line
        borderWidth: 2,
      },
    ],
  };
  const ChartOptions = {
    responsive: true,
    aspectRatio: 4,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "IranYekan", // Set your custom font here
          },
        },
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false, // Set to false to hide data labels on the bars
      },
    },
    indexAxis: "x", // Use 'y' for horizontal bar chart
    barPercentage: 0.7, // Adjust the value (0.0 to 1.0) to control the width of the bars
    categoryPercentage: 0.7,
  };
  return <Bar data={data} options={ChartOptions} style={{ height: 160 }} />;
}

import { useEffect, useState } from "react";
// import * as ReactDOMServer from "react-dom/server";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  // GeoJSON,
  useMapEvents,
  useMap,
} from "react-leaflet";
// import PieMarker from "../charts/PieChart";
// import Legend from "../../";
// import { selectMapRegions } from "../../../store/map/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectRegion } from "../../../store/region/regionSlice";
import { handleSelectedLocation } from "../../../store/location/locationSlice";

const bounds = [
  [25, 44],
  [40.2, 64],
];
const pinIcon = L.divIcon({
  html: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" viewBox="0 0 256 256" xml:space="preserve">

<defs>
</defs>
<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
	<line x1="0" y1="-23.963500000000003" x2="0" y2="23.963500000000003" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
	<path d="M 45 90 c -0.558 0 -1.011 -0.452 -1.011 -1.011 V 41.062 c 0 -0.558 0.453 -1.011 1.011 -1.011 s 1.011 0.453 1.011 1.011 v 47.927 C 46.011 89.548 45.558 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(102,103,107); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
	<circle cx="45.001" cy="20.531" r="20.531" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(242,63,56); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
	<circle cx="52.076" cy="13.456" r="5.056" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,158,154); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
</g>
</svg>`,
  className: "pin-icon",
});

const LocationMap = () => {
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [geoJSON, setGeoJSON] = useState(null);
  // const mapRegions = useSelector(selectMapRegions);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const selectedRegion = useSelector(selectRegion);
  // const [pinPosition, setPinPosition] = useState([32.528119, 52.764889]);
  // eslint-disable-next-line no-unused-vars
  const [isDragging, setIsDragging] = useState(false);

  // custom GeoJSON for quicker loading
  const CustomGeoJSONLayer = ({ data, selectedRegion }) => {
    const map = useMap();

    useEffect(() => {
      if (data && selectedRegion) {
        const geoJSONLayer = L.geoJSON(data, {
          style: { fillColor: "#fff", color: "#800080", dashArray: "5, 5" },
          filter: (feature) => {
            return feature.properties.name === selectedRegion.targetRegion;
          },
          onEachFeature: (feature, layer) => {
            layer.bindPopup(feature.properties.name);
          },
        });

        geoJSONLayer.addTo(map);

        return () => {
          map.removeLayer(geoJSONLayer);
        };
      }
    }, [data, selectedRegion, map]);

    return null; // React requires a return statement, but we don't render anything directly
  };
  //

  useEffect(() => {
    dispatch(handleSelectedLocation(selectedPosition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPosition]);

  useEffect(() => {
    const fetchGeoData = async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/eskanRegion.geojson`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const geojsonData = await response.json();
        setGeoJSON(geojsonData);
        // eslint-disable-next-line no-unused-vars
        const geoJSONBounds = L.geoJSON(geojsonData).getBounds();
        // setMapBounds(geoJSONBounds);
      } catch (error) {
        console.error("Error loading GeoJSON:", error);
      }
    };
    fetchGeoData();
  }, []);

  useEffect(() => {
    // const setMapBounds = (bounds) => {
    if (map && geoJSON && selectedRegion) {
      const selectedFeature = geoJSON.features.find(
        (feature) => feature.properties.name === selectedRegion.targetRegion
      );
      if (selectedFeature) {
        const bounds = L.geoJSON(selectedFeature).getBounds();
        map.fitBounds(bounds);
        // const bounds = L.geoJSON(geoJSON).getBounds();
        // map.fitBounds(bounds);
        setGeoJSON(geoJSON);
      }
    }
    // };
  }, [map, geoJSON, selectedRegion]);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setSelectedPosition([lat, lng]);
  };
  const MapClickHandler = () => {
    useMapEvents({
      click: handleMapClick,
    });

    return null;
  };

  const handleMapDrag = () => {
    requestAnimationFrame(() => {
      const center = map.getCenter();
      setSelectedPosition([center.lat, center.lng]);
    });
  };

  const MapDragHandler = () => {
    useMapEvents({
      drag: handleMapDrag,
    });

    return null;
  };

  return (
    <MapContainer
      center={[32.528119, 52.764889]}
      zoom={5}
      scrollWheelZoom={false}
      style={{ height: "415px", width: "100%", marginBottom: "10px" }}
      whenReady={(map) => setMap(map.target)}
      // maxBounds={bounds}
      maxBoundsViscosity={1.0}
    >
      <MapClickHandler />
      <MapDragHandler />
      <TileLayer
        attribution="|Eskan Iran"
        // attribution='|Eskan Iran; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        bounds={bounds}
        minZoom={5}
        // maxZoom={7}
      />
      {/* {mapRegions.map((reg) => {
        const { latitude, longitude, region } = reg;

        // Create  data
        const data = [
          {
            title: reg.completeOpeVol,
            value: reg.completeOpeVol,
            color: "#00CC00",
          },
          {
            title: reg.incompleteOpeVol,
            value: reg.incompleteOpeVol,
            color: "#c93636",
          },
        ];

        const icon = L.divIcon({
          className: "custom-icon",
          html: ReactDOMServer.renderToString(
            <PieMarker data={data} regionName={region} />
          ),
        });

        return (
          <Marker position={[latitude, longitude]} icon={icon}>
            <Popup>{region}</Popup>
          </Marker>
        );
      })} */}
      {selectedPosition.length > 0 && (
        <Marker
          position={selectedPosition}
          draggable={true}
          icon={pinIcon}
          eventHandlers={{
            mouseover: (e) => {
              e.target.openPopup();
            },
            mouseout: (e) => {
              e.target.closePopup();
            },
            dragstart: () => {
              setIsDragging(true);
            },
            dragend: (e) => {
              const { lat, lng } = e.target._latlng;
              setSelectedPosition([lat, lng]);
              setIsDragging(false);
            },
          }}
        >
          <Popup>
            Lat: {selectedPosition[0]}, Lng: {selectedPosition[1]}
          </Popup>
        </Marker>
      )}
      {/* <Legend map={map} /> */}
      {/* {geoJSON && selectedRegion && (
        <GeoJSON
          key={JSON.stringify(selectedRegion)}
          data={geoJSON}
          style={{ fillColor: "#fff", color: "#800080", dashArray: "5, 5" }}
          filter={(feature) => {
            return (
              feature.properties.name ===
              `استان ${selectedRegion && selectedRegion.province}, ایران`
            );
          }}
        />
      )} */}
      {geoJSON && selectedRegion && (
        <CustomGeoJSONLayer data={geoJSON} selectedRegion={selectedRegion} />
      )}
    </MapContainer>
  );
};

export default LocationMap;

import { Button, Card, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  handleProgressModal,
  handleProjectInfo,
  handleSuspendModal,
  selectProgressModal,
  selectSuspendModal,
} from "../../store/progress/progressSlice";

export default function ProjectCard({ project, diffDays }) {
  const dispatch = useDispatch();
  const isProgressModalOpen = useSelector(selectProgressModal);
  const isSuspendModalOpen = useSelector(selectSuspendModal);
  const handleShowProgressModal = () => {
    const data = {
      executionStatus: project?.executiveStatus,
      lastPhysicalUpdate: project?.physicalProgress,
      selectedProjectCode: project?.eskanIranCode,
    };
    dispatch(handleProgressModal(!isProgressModalOpen));
    dispatch(handleProjectInfo(data));
  };
  const handleShowSuspendModal = () => {
    const data = {
      executionStatus: project?.executiveStatus,
      lastPhysicalUpdate: project?.physicalProgress,
      selectedProjectCode: project?.eskanIranCode,
    };
    dispatch(handleSuspendModal(!isSuspendModalOpen));
    dispatch(handleProjectInfo(data));
  };

  // for progress submission button
  // const regex = /^100(\.0{1,2})?%$/;

  return (
    <Card
      className="bg-[#D4D1DD] text-right rtl text-white p-2.5 rounded-[22px]"
      bodyStyle={{
        padding: 0,
      }}
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -2px -2px 2px -2px inset, rgba(0, 0, 0, 0.1) 2px -2px 2px -2px inset, rgba(255, 255, 255, 0.1) -2px 2px 2px -2px inset, rgba(0, 0, 0, 0.1) 1px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -1px -1px 2px inset",
      }}
    >
      <Card
        className="bg-[#EDEDED] text-right rtl text-[#14014B] p-4 rounded-[22px]"
        bodyStyle={{
          padding: 0,
          position: "relative", // Add position relative to position overlay absolutely
        }}
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -2px -2px 2px -2px inset, rgba(0, 0, 0, 0.1) 2px -2px 2px -2px inset, rgba(255, 255, 255, 0.1) -2px 2px 2px -2px inset, rgba(0, 0, 0, 0.1) 1px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -1px -1px 2px inset",
        }}
      >
        <div className="flex items-center justify-center">
          <span className="text-xs font-bold">کد پروژه:</span>
          <span className="text-[13px] font-bold mr-1">
            {project?.eskanIranCode}
          </span>
        </div>

        <div className="flex justify-between">
          <span className="text-xs font-bold">آخرین تاریخ بروزرسانی:</span>
          <span className="text-xs">
            {project?.lastUpdateDate
              .toString()
              .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
          </span>
        </div>

        <div className="flex justify-between">
          <span className="text-xs font-bold"> وضعیت اجرایی:</span>
          <span className="text-xs">{project?.executiveStatus}</span>
        </div>

        <div className="flex justify-between">
          <span className="text-xs font-bold"> آخرین پیشرفت فیزیکی:</span>
          <span className="text-xs">{project?.physicalProgress}</span>
        </div>

        <div className="flex justify-between">
          <span className="text-xs font-bold">انحراف زمانی پروژه:</span>
          <span className="text-xs">{project?.deviationTime}</span>
        </div>

        <div className="flex justify-between w-full">
          <span className="text-xs font-bold w-1/3">شرح پروژه:</span>
          <Tooltip title={project.projectDescription}>
            <span className="text-xs w-2/3 truncate">
              {project?.projectDescription}
            </span>
          </Tooltip>
        </div>
      </Card>
      {/* Overlay for secondary Card */}
      {!diffDays && (
        <div className="flex justify-center text-center overlay bg-black opacity-50 rounded-[22px] absolute top-0 left-0 h-full">
          <span className="overlay-text p-3">
            در فاصله کمتر از یک هفته از آخرین ثبت امکان ثبت پیشرفت وجود ندارد
          </span>
        </div>
      )}

      <div className="flex justify-between mt-2">
        <Button
          disabled={!diffDays}
          onClick={handleShowProgressModal}
          type="primary"
          className="bg-indigo-900 text-xs"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -2px -2px 2px -2px inset, rgba(0, 0, 0, 0.1) 2px -2px 2px -2px inset, rgba(255, 255, 255, 0.1) -2px 2px 2px -2px inset, rgba(0, 0, 0, 0.1) 1px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -1px -1px 2px inset",
          }}
        >
          ثبت پیشرفت فیزیکی
        </Button>
        <Button
          onClick={handleShowSuspendModal}
          disabled={!diffDays}
          type="primary"
          className="bg-indigo-900 text-xs"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -2px -2px 2px -2px inset, rgba(0, 0, 0, 0.1) 2px -2px 2px -2px inset, rgba(255, 255, 255, 0.1) -2px 2px 2px -2px inset, rgba(0, 0, 0, 0.1) 1px 1px 2px 2px inset, rgba(255, 255, 255, 0.1) -1px -1px 2px inset",
          }}
        >
          توقف پروژه
        </Button>
      </div>
    </Card>
  );
}

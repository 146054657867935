import { Modal, Form, Spin, Button, Radio } from "antd";
import jalaliMoment from "jalali-moment";
import { Datepicker } from "@ijavad805/react-datepicker";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  closeSuspendModal,
  selectLoading,
  selectProjectCode,
  selectSuspendModal,
  suspendAsync,
} from "../../store/progress/progressSlice";
import { useState } from "react";

export default function SuspendModal() {
  const dispatch = useDispatch();
  const [suspendForm] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedSuspendDate, setSelectedSuspendDate] = useState("");
  const loading = useSelector(selectLoading);
  const selectedProjectCode = useSelector(selectProjectCode);
  const isModalOpen = useSelector(selectSuspendModal);

  const radioOptions = [
    { label: "مشکلات اجرایی", value: "مشکلات اجرایی" },
    { label: "مشکلات فنی و قراردادی", value: "مشکلات فنی و قراردادی" },
    { label: "مشکلات مالی", value: "مشکلات مالی" },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    setSubmitLoading(true);
    // Get the current date in Persian (Jalali)
    const persianDate = jalaliMoment().format("jYYYYjMMjDD");
    const data = {
      projectCode: selectedProjectCode,
      description: values.description,
      reason: values.reason,
      date: persianDate,
      suspendDate: selectedSuspendDate,
    };
    dispatch(suspendAsync(data)).then((action) => {
      if (action.payload.statusCode === 200) {
        toast("اطلاعات توقف پروژه با موفقیت ثبت شد.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => dispatch(closeSuspendModal()),
        });
        suspendForm.resetFields();
        setSubmitLoading(false);
      } else {
        toast("خطایی رخ داده است مجدد در زمان دیگری اطلاعات را ثبت نمایید.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => dispatch(closeSuspendModal()),
        });
        suspendForm.resetFields();
        setSubmitLoading(false);
      }
    });
  };

  const onDateChange = (date) => {
    // Input Gregorian date
    const gregorianDate = date;
    // Convert to Persian (Jalali) date
    const persianDate = jalaliMoment(gregorianDate).format("jYYYYjMMjDD");
    setSelectedSuspendDate(persianDate);
  };

  return (
    <Modal
      className="rtl"
      title="پنجره ثبت توقف پروژه"
      open={isModalOpen}
      onCancel={() => {
        suspendForm.resetFields();
        dispatch(closeSuspendModal());
      }}
      footer={false}
    >
      <Spin spinning={loading}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Form
          labelCol={{
            span: 8.5,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            direction: "rtl",
          }}
          form={suspendForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h3 style={{ marginBottom: 10 }}>
            <span>پروژه:</span>
            <span className="mr-1 font-bold">{selectedProjectCode}</span>
          </h3>

          <Form.Item
            name="reason"
            label="علت توقف پروژه را انتخاب کنید:"
            rules={[
              {
                required: true,
                message: "علت توقف پروژه را انتخاب کنید.",
              },
            ]}
          >
            <Radio.Group options={radioOptions} />
          </Form.Item>

          <Form.Item
            label="شرح مختصری در ارتباط با توقف پروژه :"
            name="description"
            rules={[
              {
                required: true,
                message: "توضیح در مورد پروژه لازم است.",
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder="توضیحی در ارتباط با توقف پروژه بنویسید..."
            />
          </Form.Item>
          <Form.Item
            label="تاریخ شروع توقف پروژه را انتخاب کنید:"
            name="startDate"
            rules={[
              {
                required: true,
                message: "تاریخ شروع توقف پروژه را انتخاب کنید.",
              },
            ]}
          >
            <Datepicker
              footer={function noRefCheck() {}}
              format="YYYY-MM-DD"
              disabledDate={(day) => day > moment()} // till yesterday should be disabled
              input={<input placeholder="تاریخ را وارد نمایید" />}
              lang="fa"
              modeTheme="light"
              theme="blue"
              onChange={(val) => {
                onDateChange(val);
              }}
            />
          </Form.Item>

          <Form.Item
          // wrapperCol={{
          //   offset: 8,
          //   span: 16,
          // }}
          >
            <Button
              className="bg-indigo-900"
              type="primary"
              htmlType="submit"
              disabled={submitLoading}
            >
              ثبت
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

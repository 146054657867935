import ReportsHeader from "../../components/Reports/ReportsHeader";

const ReportsPage = () => {
  return (
    <>
      <ReportsHeader />
    </>
  );
};

export default ReportsPage;

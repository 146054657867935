import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { progressApi } from "../../api/progressAPI";
import { isAxiosError } from "axios";
import { suspendApi } from "../../api/suspendAPI";

const initialState = {
  executionStatus: "",
  lastPhysicalUpdate: "",
  selectedProjectCode: null,
  progressModalOpen: false,
  suspendModalOpen: false,
  loading: false,
};

export const progressAsync = createAsyncThunk(
  "progress",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await progressApi(data);
      // The value we return becomes the `fulfilled` action payload

      return fulfillWithValue({ statusCode: response?.status });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.message });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);
export const suspendAsync = createAsyncThunk(
  "suspend",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await suspendApi(data);
      // The value we return becomes the `fulfilled` action payload

      return fulfillWithValue({ statusCode: response?.status });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    handleProgressModal: (state, action) => {
      state.progressModalOpen = action.payload;
    },
    closeProgressModal: (state) => {
      state.progressModalOpen = false;
    },
    handleSuspendModal: (state, action) => {
      state.suspendModalOpen = action.payload;
    },
    closeSuspendModal: (state) => {
      state.suspendModalOpen = false;
    },
    handleProjectInfo: (state, action) => {
      state.executionStatus = action.payload.executionStatus;
      state.lastPhysicalUpdate = action.payload.lastPhysicalUpdate;
      state.selectedProjectCode = action.payload.selectedProjectCode;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(progressAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(progressAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(progressAsync.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(suspendAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(suspendAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(suspendAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectLoading = (state) => state.progress.loading;
export const selectProgressModal = (state) => state.progress.progressModalOpen;
export const selectSuspendModal = (state) => state.progress.suspendModalOpen;
export const selectProjectCode = (state) => state.progress.selectedProjectCode;
export const selectPhysicalProgress = (state) =>
  state.progress.lastPhysicalUpdate;
export const selectExecutionStatus = (state) => state.progress.executionStatus;

export const {
  handleProgressModal,
  closeProgressModal,
  handleProjectInfo,
  handleSuspendModal,
  closeSuspendModal,
} = progressSlice.actions;
export default progressSlice.reducer;

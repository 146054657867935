import { jwtAxios } from ".";

export const CHART_API_URL = "/chart";
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function chartApi(regionCode) {
  return jwtAxios.get(BASE_URL + CHART_API_URL, {
    params: {
      regionCode,
    },
  });
}

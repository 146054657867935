import { jwtAxios } from ".";

export const SUSPEND_API_URL = `/suspend`;
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export function suspendApi(data) {
  return jwtAxios.post(BASE_URL + SUSPEND_API_URL, { data });
}

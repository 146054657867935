import React, { useState, useEffect } from "react";

const NumberAnimation = ({ endValue, duration }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    let startTimestamp;
    let requestId;

    const updateNumber = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = timestamp - startTimestamp;
      const percentage = Math.min(progress / duration, 1);

      setCurrentValue(Math.floor(percentage * endValue));

      if (percentage < 1) {
        requestId = requestAnimationFrame(updateNumber);
      }
    };

    requestId = requestAnimationFrame(updateNumber);

    return () => cancelAnimationFrame(requestId);
  }, [endValue, duration]);

  return <>{currentValue}</>;
};

export default NumberAnimation;

export const getEndValue = (progress) => {
  if (!progress) return 0;

  const number = progress.replace("%", "");

  return +number;
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { regionApi } from "../../api/regionAPI";
import { isAxiosError } from "axios";
import { chartApi } from "../../api/chartAPI";

const initialState = {
  charts: [],
  loading: false,
};

// export const useAsyncLogout = false;

export const chartAsync = createAsyncThunk(
  "chart",
  async (regionCode, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await chartApi(regionCode);

      // The value we return becomes the `fulfilled` action payload

      return fulfillWithValue({ charts: response?.data.chart });
    } catch (err) {
      if (isAxiosError(err))
        return rejectWithValue({ message: err.response?.data.data });
      return rejectWithValue({ message: "Bad Request" });
    }
  }
);

export const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    // syncState: (state) => {
    //   state.user = getLocalStorage("user", null);
    //   state.isAuthenticated = getLocalStorage("isAuthenticated", false);
    // },
    // logout: (state) => {
    //   state.user = null;
    //   state.isAuthenticated = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(chartAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(chartAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.charts = action.payload.charts;
        }
        state.loading = false;
      })
      .addCase(chartAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

// export const selectAuth = (state) => state.auth;
// export const selectToken = (state) => state.auth.user?.token;
export const selectLoading = (state) => state.chart.loading;
// export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectCharts = (state) => state.chart?.charts;

// export const { syncState, logout } = authSlice.actions;
export default chartSlice.reducer;

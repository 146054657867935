import { Card, Col, Row, Skeleton, Space } from "antd";
import styles from "./styles/DashHeader.module.scss";
import { useSelector } from "react-redux";
import { selectRegion, selectLoading } from "../../store/region/regionSlice";
import NumberAnimation, { getEndValue } from "../shared/NumberAnimation";

export default function ProgressStatistics() {
  const region = useSelector(selectRegion);
  const loading = useSelector(selectLoading);
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
      }}
    >
      {/* <Spin spinning={loading}> */}
      <Row
        justify="space-between"
        align="bottom"
        className={styles.DashboardTop}
        gutter={[16, 0]}
        style={{ margin: "10px 0px 40px 0px" }}
      >
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {region ? (
            <span
              style={{
                color: "#14014B",
                fontSize: 48,
                fontWeight: 600,
                textShadow: "1px 2px 5px rgba(0, 0, 0, 0.3)",
              }}
            >
              <NumberAnimation
                endValue={getEndValue(region?.BudgetProgress)}
                duration={1000}
              />
              %
            </span>
          ) : (
            <Skeleton.Button
              size="small"
              style={{
                width: 70,
                height: 25,
                display: "flex",
              }}
              active
              loading={loading}
            />
          )}
          <Card
            style={{
              backgroundColor: "#14014B",
              height: 30,
              width: 90,
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 2,
            }}
          >
            <span style={{ color: "#fff" }}>(CV) بودجه ای</span>
          </Card>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {region ? (
            <span
              style={{
                color: "#14014B",
                fontSize: 48,
                fontWeight: 600,
                textShadow: "1px 2px 5px rgba(0, 0, 0, 0.3)",
              }}
            >
              <NumberAnimation
                endValue={getEndValue(region?.ProgrammaticProgress)}
                duration={1000}
              />
              %
            </span>
          ) : (
            <Skeleton.Button
              size="small"
              style={{
                width: 70,
                height: 25,
                display: "flex",
              }}
              active
              loading={loading}
            />
          )}
          <Card
            style={{
              backgroundColor: "#14014B",
              height: 30,
              width: 90,
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 2,
            }}
          >
            <span style={{ color: "#fff" }}>(PV) برنامه ای</span>
          </Card>
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {region ? (
            <span
              style={{
                color: "#14014B",
                fontSize: 48,
                fontWeight: 600,
                textShadow: "1px 2px 5px rgba(0, 0, 0, 0.3)",
              }}
            >
              <NumberAnimation
                endValue={getEndValue(region?.physicalProgress)}
                duration={1000}
              />
              %
            </span>
          ) : (
            <Skeleton.Button
              size="small"
              style={{
                width: 70,
                height: 25,
                display: "flex",
              }}
              active
              loading={loading}
            />
          )}
          <Card
            style={{
              backgroundColor: "#14014B",
              height: 30,
              width: 90,
              boxShadow:
                "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            size="default"
            headStyle={{
              borderBottom: "unset",
              fontSize: 14,
              fontWeight: 500,
            }}
            bodyStyle={{
              padding: 2,
            }}
          >
            <span style={{ color: "#fff" }}>(EV) فیزیکی</span>
          </Card>
        </Col>
      </Row>
      {/* </Spin> */}
    </Space>
  );
}

import { Card, Col, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { selectLoading, selectRegion } from "../../../store/region/regionSlice";
import ProjectStatusChart from "../charts/ProjectStatusChart";

export default function ProjectStatus() {
  const regionData = useSelector(selectRegion);
  const loading = useSelector(selectLoading);
  return (
    <Row gutter={[24, 0]} style={{ height: "100%" }}>
      <Col span={12}>
        {/* <Card
          style={{
            height: "100%",
            padding: "0px 0px",
            boxShadow:
              "5px 5px 5px 0px rgba(0,0,0,0.3)," +
              "5px 5px 5px 0px rgba(0,0,0,0)," +
              "5px 5px 5px 0px rgba(0,0,0,0)",
            border: "none",
            backgroundColor: "#E7E6E6",
          }}
          bodyStyle={{
            padding: 3,
            height: "100%",
          }}
        > */}
        <ProjectStatusChart />
        {/* </Card> */}
      </Col>
      <Col span={12} style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
            height: "100%",
            gap: 6,
          }}
        >
          <Row gutter={[6, 0]}>
            <Col span={10}>
              <Card
                style={{
                  height: "25px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#3B3838",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                <span style={{ color: "#fff", fontSize: "12px" }}>
                  (میلیارد ریال) اعتبار
                </span>
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "25px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#3B3838",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                <span style={{ color: "#fff", fontSize: "12px" }}>
                  تعداد پروژه
                </span>
              </Card>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col span={10}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.preRunCredit}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 70,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.preRunProj}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 15,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#45818E",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>
                  پیش اجرا
                </span>
              </Card>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col span={10}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.currentCredit}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 70,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.currentProject}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 15,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#598234",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                <span style={{ color: "#fff", fontSize: "14px" }}>جاری</span>
              </Card>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col span={10}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.finishedCredit}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 70,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.finishedProj}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 15,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#FFE599",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                <span style={{ color: "rgb(0,0,0)", fontSize: "14px" }}>
                  اتمام عملیات
                </span>
              </Card>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col span={10}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.holdCredit}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 70,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.holdProj}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 15,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#C00000",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  متوقف
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={[6, 0]}>
            <Col span={10}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.cancelledCredit}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 70,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#E7E6E6",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                bodyStyle={{
                  padding: 3,
                }}
              >
                {regionData ? (
                  <span style={{ fontSize: "18px", fontWeight: 600 }}>
                    {regionData?.cancelledProj}
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton.Button
                      size="small"
                      style={{
                        width: 15,
                        height: 15,
                        display: "flex",
                      }}
                      active
                      loading={loading}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col span={7}>
              <Card
                style={{
                  height: "30px",
                  padding: "0px 0px",
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                  backgroundColor: "#767171",
                  display: "flex",
                  justifyContent: "center",
                }}
                bodyStyle={{
                  padding: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#fff", fontSize: "12.5px" }}>
                    /ابطال
                  </span>
                  <span
                    style={{ color: "#fff", fontSize: "12px", marginTop: -6 }}
                  >
                    درخواست ابطال
                  </span>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

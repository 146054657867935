import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { useSelector } from "react-redux";
import { selectRegion } from "../../../store/region/regionSlice";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ChartDataLabels, ...registerables);

export default function ProjectStatusChart() {
  const region = useSelector(selectRegion);
  const data = {
    labels: ["پیش اجرا", "جاری", "اتمام عملیات", "متوقف", ".../ابطال"],
    datasets: [
      {
        data: [
          region?.preRunCredit,
          region?.currentCredit,
          region?.finishedCredit,
          region?.holdCredit,
          region?.cancelledCredit,
        ],
        backgroundColor: [
          "rgba(69, 129, 142, 1)",
          "rgba(89, 130, 52, 1)",
          "rgba(255, 229, 153, 1)",
          "rgba(192, 0, 0, 1)",
          "rgba(118, 113, 113, 1)",
        ],
        borderColor: [
          "rgba(69, 129, 142, 1)",
          "rgba(89, 130, 52, 1)",
          "rgba(255, 229, 153, 1)",
          "rgba(192, 0, 0, 1)",
          "rgba(118, 113, 113, 0.9)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const ChartOptions = {
    responsive: true,
    aspectRatio: 4,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          font: {
            family: "IranYekan",
          },
          maxRotation: 0,
          minRotation: 0,
          offset: 0,
        },
      },
      y: {
        beginAtZero: true,
        display: false, // Hide y-axis labels
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
        offset: 0,
        padding: 0,
      },
    },

    title: {
      display: false, // Hide legend label
    },
  };
  return (
    <Bar
      options={ChartOptions}
      data={data}
      style={{ height: 60, width: 100 }}
    />
  );
}

import axios from "axios";
import { getLocalStorage } from "../helpers";
// export const REFRESH_TOKEN_API_URL = `/accounts/refresh-token`;
// export const useRefreshToken = false;

export const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export const Axios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const instanceAxios = (extraHeaders) => {
  let headers = { "Content-Type": "application/json" };
  if (extraHeaders) headers = JSON.parse(JSON.stringify(extraHeaders));
  const ia = axios.create({
    withCredentials: true,
    baseURL:
      process.env.REACT_APP_ENV === "development"
        ? process.env.REACT_APP_BASE_URL
        : process.env.REACT_APP_BASE_URL_PRODUCTION,
    headers,
  });

  ia.interceptors.response.use(
    (response) => response,

    (error) => Promise.reject(error)
  );
  return ia;
};

const jwtAxios = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

jwtAxios.interceptors.request.use((config) => {
  let token = getLocalStorage("token", null);
  if (!token) {
    // localStorage.clear();
    // window.location.href = "/login";
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

jwtAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      // Unauthorized

      // if (useRefreshToken) {
      //   let authData = (getLocalStorage < AuthUser) | (null > ("user", null));
      //   const payload = {
      //     access_token: authData?.jwtToken,
      //   };
      //   let apiResponse = await jwtAxios.post(REFRESH_TOKEN_API_URL, payload);
      //   localStorage.setItem("isAuthenticate", "true");
      //   localStorage.setItem("user", JSON.stringify(apiResponse?.data));
      //   error.config.headers[
      //     "Authorization"
      //   ] = `Bearer ${apiResponse?.data?.jwtToken}`;
      //   return axios(error.config);
      // } else {
      localStorage.clear();
      window.location.href = "/login";
      // }
    } else {
      return Promise.reject(error);
    }
  }
);
export { jwtAxios };

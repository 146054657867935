import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectProjectDetails } from "../../../store/projects/projectsSlice";

export default function ContractInformation() {
  const projectDetails = useSelector(selectProjectDetails);

  return (
    <div className="rtl mt-16 bg-[#e2e1e1] flex flex-col gap-5  py-4 px-4 rounded-bl-3xl  relative">
      <Row gutter={16}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              رویکرد
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.contractualApproach}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              درصد مشارکت
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.participationPercentage}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              وضعیت پیمان
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.treatyStatus}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-[13.5px] h-[36px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              ارگان/دستگاه اجرایی
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.executiveBody}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32  text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              وضعیت منشور
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.charterStatus}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className="flex gap-3">
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-32 text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              نوع پیمان
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.typeOfContract}
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={16}>
          <div className="flex gap-3" style={{ width: "calc(100% - 60px)" }}>
            <div
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#14014B] text-white w-1/4 h-[92px] text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              نام پیمانکاران
            </div>
            <div
              style={{
                boxShadow:
                  "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                  "5px 5px 5px 0px rgba(0,0,0,0)," +
                  "5px -5px 5px 0px rgba(0,0,0,0)",
                border: "none",
              }}
              className="bg-slate-50 text-stone-950 w-3/4 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              {projectDetails?.nameOfContractors}
            </div>
          </div>
        </Col>

        <Col span={8}>
          <Row gutter={10} className="mb-5">
            <Col>
              <div
                style={{
                  boxShadow:
                    "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
                }}
                className="bg-[#14014B] text-white w-32 h-[36px] text-[14px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
              >
                تاریخ آخرین پیمان
              </div>
            </Col>
            <Col>
              <div
                style={{
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px -5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                }}
                className="bg-slate-50 text-stone-950 w-48 h-[36px] text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
              >
                {projectDetails?.lastAgreementDate
                  ?.toString()
                  .replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3")}
              </div>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col>
              <div
                style={{
                  boxShadow:
                    "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
                }}
                className="bg-[#14014B] text-white w-32 text-[14px] h-[36px] flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
              >
                جمع مبلغ قراردادها
              </div>
            </Col>
            <Col>
              <div
                style={{
                  boxShadow:
                    "5px 5px 5px 0px rgba(0,0,0,0.3)," +
                    "5px 5px 5px 0px rgba(0,0,0,0)," +
                    "5px -5px 5px 0px rgba(0,0,0,0)",
                  border: "none",
                }}
                className="bg-slate-50 text-stone-950 h-[36px] w-48 text-xl flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
              >
                {projectDetails?.totalAmountOfContract}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <span className="bg-[#D4D1DD] font-bold py-[2px] px-9 rounded-tr-3xl text-#14014B text-lg absolute top-[-31px] right-0">
        اطلاعات قرارداد و پیمان پروژه
      </span>
    </div>
  );
}

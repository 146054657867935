import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";
import LeafletMap from "./map/LeafletMap";
import RegionInfo from "./map/RegionInfo";
import ProjectStatus from "./map/ProjectStatus";
import { selectRegion } from "../../store/region/regionSlice";

export default function DashboardRegionSection() {
  const region = useSelector(selectRegion);

  return (
    <Row gutter={[56, 20]} style={{ marginBottom: 40 }}>
      <Col span={10} style={{ paddingLeft: 38 }}>
        <Card
          style={{
            height: "100%",
            padding: "0px 0px",
            position: "relative",
            borderRadius: "0px",
            boxShadow:
              "5px 5px 5px 0px rgba(20,1,75,0.3)," +
              "5px 5px 5px 0px rgba(0,0,0,0)," +
              "5px -5px 5px 0px rgba(0,0,0,0)," +
              "0px 0px 5px 0px rgba(135,116,225,0.3)",
          }}
          bodyStyle={{
            padding: 3,
          }}
        >
          <span
            style={{
              position: "absolute",
              right: 0,
              top: -20,
              backgroundColor: "#D4D1DD",
              borderRadius: 5,
              padding: "0px 10px",
              color: "#14014B",
              font: "16px",
              fontWeight: "bold",
            }}
          >
            {` جانمایی منطقه و پروژه های ${region ? region?.targetRegion : ""}`}
          </span>
          <LeafletMap />
        </Card>
      </Col>
      <Col span={14}>
        <Row>
          <Card
            style={{
              height: `135px`,
              width: "100%",
              marginBottom: "65px",
              direction: "rtl",
              boxShadow:
                "5px 5px 5px 0px rgba(20,1,75,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)," +
                "0px 0px 5px 0px rgba(135,116,225,0.3)",
              border: "none",
              borderRadius: "0px",
            }}
            bodyStyle={{
              padding: 10,
            }}
          >
            <RegionInfo />
          </Card>
        </Row>
        <Row>
          <Card
            style={{
              height: `240px`,
              width: "100%",
              boxShadow:
                "5px 5px 5px 0px rgba(20,1,75,0.3)," +
                "5px 5px 5px 0px rgba(0,0,0,0)," +
                "5px -5px 5px 0px rgba(0,0,0,0)," +
                "0px 0px 5px 0px rgba(135,116,225,0.3)",
              border: "none",
              position: "relative",
              borderRadius: "0px",
            }}
            bodyStyle={{
              padding: 10,
              height: "100%",
            }}
          >
            <span
              style={{
                position: "absolute",
                right: 0,
                top: -20,
                backgroundColor: "#D4D1DD",
                borderRadius: 5,
                padding: "0px 10px",
                color: "#14014B",
                font: "16px",
                fontWeight: "bold",
              }}
            >
              وضعیت اجرایی پروژه ها
            </span>
            <ProjectStatus />
          </Card>
        </Row>
      </Col>
    </Row>
  );
}

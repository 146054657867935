import ProjectsHeader from "../../components/Projects/ProjectsHeader";
import ProjectsTable from "../../components/Projects/ProjectsTable";

const ProjectsPage = () => {
  return (
    <>
      <ProjectsHeader />
      <ProjectsTable />
    </>
  );
};

export default ProjectsPage;

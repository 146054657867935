import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ConfigProvider } from "antd";
import "react-toastify/dist/ReactToastify.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement
);

const theme = {
  token: {
    colorPrimary: "#722ed1",
    colorInfo: "#722ed1",
    colorTextBase: "#000",
    colorBgTextHover: "rgba(30, 0, 100, 0.04)",
    // colorBgContainer: "#926BD3",
  },
  components: {
    Progress: {
      circleTextFontSize: "26px",
    },
  },
};

function App() {
  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;

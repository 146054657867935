import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { selectProjectLinks } from "../../../store/projects/projectsSlice";

export default function ProjectLinks() {
  const projectLinks = useSelector(selectProjectLinks);

  return (
    <div className="rtl mt-16 bg-[#e2e1e1] flex flex-col gap-5 items-start py-4 px-4 rounded-bl-3xl  relative">
      <Row gutter={36} style={{ width: "100%" }}>
        <Col span={12}>
          <a
            href={projectLinks?.projectDocumentLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="button"
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className="bg-[#EB5457] w-1/2 text-white  text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg"
            >
              ابلاغیه پروژه
            </Button>
          </a>
        </Col>

        <Col span={12}>
          <a
            href={projectLinks?.projectCharterLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="button"
              style={{
                boxShadow:
                  "inset 0.3em 0.3em 0.3em 0 rgba(255, 255, 255, 0.2), inset -0.3em -0.3em 0.3em 0 rgba(0, 0, 0, 0.2)",
              }}
              className={`w-1/2 text-white text-lg flex items-center justify-center py-1 px-3 border-bevel rounded-lg ${
                projectLinks?.projectCharterLink === ""
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-[#EB5457] hover:bg-red-600"
              }`}
              disabled={projectLinks?.projectCharterLink === ""}
            >
              منشور پروژه
            </Button>
          </a>
        </Col>
      </Row>

      <span className="bg-[#D4D1DD] font-bold py-[2px] px-9 rounded-tr-3xl text-#14014B text-lg absolute top-[-31px] right-0">
        فایل های پیوست پروژه
      </span>
    </div>
  );
}

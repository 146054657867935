import { Table, Button, Input, Space } from "antd";
import "./styles/LocationsTable.css";
import { useDispatch, useSelector } from "react-redux";
import {
  projectsAsync,
  selectLoading,
  selectProjects,
} from "../../store/projects/projectsSlice";
import { useEffect } from "react";
import { selectSelectedRegion } from "../../store/region/regionSlice";
import { SearchOutlined } from "@ant-design/icons";
import {
  handleLocationModal,
  handleSelectedProject,
  selectLocationModal,
} from "../../store/location/locationSlice";

export default function LocationsTable() {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const loading = useSelector(selectLoading);
  const selectedRegion = useSelector(selectSelectedRegion);
  const isLocationModalVisible = useSelector(selectLocationModal);

  // location modal
  const handleModal = (project) => {
    dispatch(handleSelectedProject(project));
    dispatch(handleLocationModal(!isLocationModalVisible));
  };

  // fetch projects
  useEffect(() => {
    dispatch(projectsAsync(selectedRegion));
  }, [dispatch, selectedRegion]);

  const codeRegex = /([a-zA-Z]+)(\d+)/;
  const dataSource =
    projects &&
    projects
      .map((project, index) => ({ ...project, key: index }))
      .filter((project) => {
        const statusToFilter = "ابطال";
        return !project.executiveStatus.includes(statusToFilter);
      })
      .sort((a, b) => {
        const codeA = a.eskanIranCode.match(codeRegex);
        const codeB = b.eskanIranCode.match(codeRegex);

        const lettersA = codeA[1];
        const lettersB = codeB[1];

        if (lettersA < lettersB) return -1;
        if (lettersA > lettersB) return 1;

        const numA = parseInt(codeA[2]);
        const numB = parseInt(codeB[2]);

        if (numA < numB) return -1;
        if (numA > numB) return 1;

        return 0;
      });
  console.log(dataSource);

  const columns = [
    {
      title: "کد پروژه",
      dataIndex: "projectCode",
      key: "projectCode",
      width: "10%",
      render: (_, project) => (
        <div
          className="bg-slate-100 text-center text-[#14014B] p-1"
          style={{
            boxShadow:
              "inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5)",
          }}
        >
          {project.eskanIranCode}
        </div>
      ),
    },
    {
      title: "نام پروژه",
      dataIndex: "projectName",
      key: "projectName",
      width: "60%",
      render: (_, project) => (
        <div
          className="bg-slate-100 text-center text-[#14014B] p-1 truncate"
          style={{
            boxShadow:
              "inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5)",
          }}
        >
          {project.projectDescription}
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#722ed1" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.projectDescription
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{ padding: 8, direction: "rtl" }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            direction="rtl"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined style={{ verticalAlign: "middle" }} />}
              size="small"
              style={{ width: 90, backgroundColor: "#722ed1" }}
            >
              جستجو
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "عملیات",
      key: "actions",
      render: (_, project) => {
        const locationsLength = project.locations.length;

        return (
          <div className="flex justify-center text-sm">
            <ActionButton
              type="button"
              label="ثبت موقعیت"
              className=" bg-indigo-900 hover:bg-indigo-700 ml-2 rounded-xl"
              onClick={() => {
                handleModal(project);
              }}
              style={{
                boxShadow:
                  "inset 0.2em 0.2em 0.2em 0 rgba(255, 255, 255, 0.5), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5)",
              }}
            />
            {/* <ActionButton
              type="button"
              label="ویرایش"
              className="bg-red-600 hover:bg-red-400 ml-2"
            /> */}
            {locationsLength > 0 ? (
              <ActionButton
                type="button"
                label={`${locationsLength} مکان ثبت شده`}
                className="bg-green-500"
                disabled
              />
            ) : (
              <ActionButton
                type="button"
                className="bg-white w-[116px]" // Set your desired styles for no locations
                disabled
              />
            )}
          </div>
        );
      },
      align: "center",
    },
  ];
  const ActionButton = ({
    type,
    label,
    className,
    disabled,
    onClick,
    style,
  }) => {
    return (
      <Button
        disabled={disabled}
        type={type}
        className={` text-white text-[13px] ${className}`}
        onClick={onClick}
        style={style}
      >
        {label}
      </Button>
    );
  };

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      className="customTable"
      pagination={false}
    />
  );
}

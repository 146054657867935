import { jwtAxios } from ".";

export const LOGIN_API_URL = `/auth/login`;
const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_PRODUCTION;
// export const LOGOUT_API_URL = `/accounts/revoke-token`;

export function loginApi(username, password) {
  return jwtAxios.post(BASE_URL + LOGIN_API_URL, {
    username,
    password,
  });
}

// export function logoutApi() {
//   const user = (getLocalStorage < AuthUser) | (null > ("user", null));
//   return jwtAxios.post(BASE_URL + LOGOUT_API_URL, { token: user?.jwtToken });
// }
